import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useState, useEffect, useRef } from "react"
import { useCollection } from '../../hooks/useCollection'
import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from '../../hooks/useAuthContext'
import { usePageContext } from '../../hooks/usePageContext'

import MainContainer from '../../components/Layout/MainContainer/MainContainer'
import VendorProductFilter from './VendorProductFilter'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { collection, onSnapshot, query } from 'firebase/firestore'

import { Link, NavLink as RouterLink } from "react-router-dom"

import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import ProductModal from '../ProductModal'

import FarmsList from './FarmsList/FarmsList'
import StrainsList from './StrainsList/StrainsList'

import VendorLogoList from './VendorLogoList'
import Scroller from '../Layout/Scroller/Scroller'
import Grid from '@mui/material/Grid2'

import { useTheme } from '@mui/material/styles'

import ShabazzAffiliates from '../../pages/shabazz/ShabazzAffiliates'
import ShabazzToolbar from '../../pages/shabazz/ShabazzToolbar'

import HempHarvestHeaderBG from '../../pages/hempharvest2024/hempHarvestHeaderBG-full.jpg'
import hempHarvestLogo from '../../pages/hempharvest2024/hempharvestlogo-trans-400.png'

import FriendsModal from './Modals/FriendsModal'
import WelcomeModal from './Modals/WelcomeModal'

export default function VendorInventory( { mode = "farms", vendors = [] } ) {
   const { scrollMarginTop, headerBG, headerBGColor } = usePageContext()

   // This is the problem with Shabazz's header not showing...
   // console.log( 'headerBG', headerBG )
   
   const theme = useTheme()

   const styles = {
      videoIframeWrapper: {
         padding: '56.25% 0 0 0',
         position: 'relative'
      },
      videoIframe: {
         position: 'absolute',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%'
      },
      logo: {
         marginRight: theme.spacing( 2 ),
      },
      title: {
         flexGrow: 1,
      },
      introSection: {
         padding: { xs: '0', sm: '2em' },
      },
      hempHarvestContainer: {
         backgroundImage: `url("${ HempHarvestHeaderBG }")`,
         backgroundSize: 'cover',
         backgroundPosition: 'top',
         borderLeft: '21px double #3D5125',
         borderRight: '21px double #3D5125',
         height: '600px',
      },
      hempHarvestBanner: {
         backgroundImage: `url("${ HempHarvestHeaderBG }")`,
         backgroundSize: 'cover',
         backgroundPosition: 'middle',
         paddingTop: '2rem',
         paddingBottom: '2rem',
      },
      hempMarvestBannerContent: {
         backgroundColor: '#ffffffde',
         borderRadius: '28px',
         paddingTop: '1rem',
         paddingBottom: '1rem',
         textAlign: 'center',
         justifyContent: 'center',
      },
      hempHarvestLogo: {
         width: '100%',
         maxWidth: '350px',
      },
      hempHarvestBannerText: {
         fontSize: { 'xs': '1.3rem', 'sm': '1.6rem' },
         fontWeight: '400',
         textAlign: 'center',
         color: 'secondary.main',
         maxWidth: '900px',
         marginLeft: 'auto',
         marginRight: 'auto',
      },
      hempHarvestBannerButton: {
         fontWeight: '700',
         marginTop: '1rem',
      },
      sectionHeader: {
         marginTop: '2rem',
         marginBottom: '1rem',
         fontWeight: 'bold',
         textAlign: { 'xs': 'center', 'sm': 'left' },
      },
      sectionBlockHeader: {
         marginTop: '2rem',
         marginBottom: '1rem',
         textAlign: { 'xs': 'center', 'sm': 'left' },
         color: 'white.main',
         padding: { xs: '1rem', sm: '1.5rem' },
         paddingLeft: { xs: '1rem', sm: '3rem' },
         fontFamily: "'Kalam', cursive",
         marginLeft: { xs: '0', sm: '-40px' },
         fontSize: { 'sm': '2rem', 'md': '2.5rem' },
      },
      strainStatusKey: {
         display: 'flex',
         marginBottom: '1.5rem',
         marginTop: '.2rem',
         justifyContent: { xs: 'center', sm: 'left' },
      },
      farmBioHeader: {
         textAlign: { xs: 'center', sm: 'left' }
      },
      farmBioName: {
         borderBottom: '1px solid #3d5125',
         paddingBottom: '.5rem',
         marginBottom: '1rem',
         textAlign: { 'xs': 'center', 'sm': 'left' },
      },
      farmBioBody: {
         fontSize: { xs: '1.1rem', sm: '1.2rem' },
         marginBottom: '2rem',
         marginTop: '1rem'
      },
      borderBG: {
         borderRadius: '28px',
         backgroundColor: '#ffffff',
         background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 50%)',
         padding: { xs: '.5rem', sm: '1.5rem', md: '2rem' },
         marginTop: '2rem',
         // borderTop: '7px solid #7baa45',
      }
   }
   
   const { user } = useAuthContext()

   const sortVendors = ( vendors, sortType = 'alphabetical' ) => {
      let sortedVendors = []
      
      // Consider doing a random sort
      //const sortedVendors = vendors.sort( () => Math.random() - 0.5 )
      
      /*
      if ( sortType === 'newest' ) {
         sortedVendors = vendors.sort( ( a, b ) => a.date.getTime() - b.date.getTime()) )
      }
      */

      if ( sortType === 'alphabetical' ) {
         sortedVendors = vendors.sort( ( a, b ) => a.title.localeCompare( b.title ) )
      }      

      // Part of the Featured Fendor
      /*
      const featuredVendorIdx = vendors.findIndex( p => p.slug === 'neverwinterbotanicals' )

      if ( featuredVendorIdx !== -1 ) {
         const removed = sortedVendors.splice( featuredVendorIdx, 1 )
         sortedVendors.unshift( removed[0] )
      }
      */

      return sortedVendors
   }

   const productSort = ( prods, dateCol = 'queue_date' ) => {
      const newProds = [ ...prods ]
      newProds.sort( ( x, y ) => y[ dateCol ].seconds - x[ dateCol ].seconds )
      return newProds
   }
   
   let vendorList = vendors

   // Get vendorList from DB
   let { documents: vendorListRef } = useCollection(
      'vendorLists/mostPopular/vendors',
      [[ 'active', '==', true ]]
   )

   // If we didn't pass in vendors, use the ones from the DB
   if ( !vendorList.length && vendorListRef ) {
      
      // Alphatize dat bitch
      vendorList = sortVendors( vendorListRef )
   }

   // Get user's data
   const { document: userData } = useDocument( 'users', user ? user.uid : '0' )

   const defaultFilters = {
      type: [],
      stock: true,
      effect: [],
      enviro: [],
      shabazz: false,
      new: false,
      sale: false,
      fav: false,
      intl: false,
      text: "",
      hh24: false,
   }

   const savedFilters = JSON.parse( localStorage.getItem( 'filters' ) ) || {}

   // merge saved filters with default filters with saved filters taking precedence
   const mergedFilters = { ...defaultFilters, ...savedFilters }

   const [ filters, setFilters ] = useState( mergedFilters )

   // Generalized function to updating filter state.
   const updateFilter = ( key, value ) => {
      setFilters( prevFilters => {
         
         const newFilters = {
            ...prevFilters,
            [ key ]: value
         }

         localStorage.setItem( 'filters', JSON.stringify( newFilters ) )
         
         return newFilters
      } )
   }

   const [ productModalOpen, setProductModalOpen ] = useState( false )
   const [ friendsModalOpen, setFriendsModalOpen ] = useState( false )
   const [ welcomeModalOpen, setWelcomeModalOpen ] = useState( false )
   const [ productModalData, setProductModalData ] = useState( false )
   
   const [ products, setProducts ] = useState( [] )
   const [ filteredProducts, setFilteredProducts ] = useState( [] )
   const [ giveawayModalOpen, setGiveawayModalOpen ] = useState( false )
   
   // Product Card thumbnail/title
   const handleClickOpen = ( product ) => {
      
      // GA4 Event
      window.dataLayer.push( {
         event: 'product_card',
         uid: user ? user.uid : '',
         vendor_slug: product.vendorSlug,
         vendor_name: product.vendorName,
         product_id: product.id,
         product_title: product.title
      } )
      setProductModalData( product )
      setProductModalOpen( true );
   };

   const handleFriendsClickOpen = () => {
      setFriendsModalOpen( true )
   }
  
   const handleClose = () => {
      setProductModalOpen( false )
      setFriendsModalOpen( false )
      setGiveawayModalOpen( false )
   };

   const handleFav = ( product ) => {
      let favsArray = []
      
      if ( userData.favs && userData.favs.length ) {
         favsArray = userData.favs
         
         if ( ! favsArray.includes( product.id ) ) {
            favsArray.push( product.id )
         } else {
            favsArray.splice(favsArray.indexOf( product.id ), 1);
         }
      } else {
         favsArray.push( product.id )
      }
      
      try {
         updateDoc( doc ( db, 'users', user.uid ), { favs: favsArray } )
      } 
      catch ( error ) {
         console.log('error', error)
      }
   }

   const resetFilters = () => {
      localStorage.removeItem('filters')
      setFilters( defaultFilters )
   }

   const offset_timestamp = (Date.now() / 1000) - (60 * 60 * 24 * 3)

   const filterProducts = ( product ) => {
      /*
      const filters = {
         type: [],
         stock: true,
         effect: [],
         enviro: [],
         shabazz: false,
         new: false,
         sale: false,
         fav: false,
         intl: false,
         text: "",
         hh24: false,
      }
      */

      if ( ! product.in_stock && filters.stock ) return false

      // Get rid of any products that need to be verified and dropped
      if ( product.queue && (
         product.queue === 'new' || product.queue === 'restock' || product.queue === 'hide'
      ) ) return false

      if ( filters.new ) {
         let newOrRestocked = false
   
         if ( product.new_date && product.new_date.seconds > offset_timestamp ) newOrRestocked = true
         if ( product.restock_date && product.restock_date.seconds > offset_timestamp ) newOrRestocked = true

         return newOrRestocked
      }

      if ( filters.effect.length && ! filters.effect.includes( product.effect ) ) return false
      if ( filters.enviro.length && ! filters.enviro.includes( product.enviro ) ) return false
      if ( filters.type.length && ! filters.type.includes( product.hemp_type ) ) return false
      if ( filters.shabazz && ! product.ssoa ) return false
      if ( filters.sale && ! product.on_sale ) return false
      if ( filters.fav && userData && userData.favs.length && ! userData.favs.includes( product.id ) ) return false
      if ( filters.text && ! product.title.toLowerCase().includes( filters.text.toLowerCase() ) ) return false
      if ( filters.intl && ! product.intlShipping ) return false
      if ( filters.hh24 && ! product.hh24 ) return false

      return true
   }

   useEffect( () => {
      // Wait until we get the vendor list back from DB
      if ( vendorList && ! products.length ) {
         let q = null

         // Get each vendor's products and put them into a products array
         vendorList.forEach( vendor => {
            q = query(
               collection( db, `vendors/${ vendor.slug }/products` ),
               //where( 'queue_date', '>', oneWeek ),
               //where( 'queue', '==', 'drop' )
            )

            // Realtime data
            const unsubscribe = onSnapshot( q, snapshot => {
               snapshot.forEach( doc => {
                  setProducts( oldArray => {

                     const productArray = [
                        // Get rid of any duplicates since we're using live data
                        ...oldArray.filter( el => el.id !== doc.id ),
                        {
                           ...doc.data(),
                           id: doc.id,
                           vendorName: vendor.title,
                           vendorSlug: vendor.slug,
                        }
                     ]

                     const fProducts = productArray.flat().filter( ( product ) => { 
                        return filterProducts( product )
                     } )

                     setFilteredProducts( fProducts )

                     return productArray
                  } )
               } )
            } )

            // Clean up function for when component unmounts
            // Don't remember what that does.
            return () => unsubscribe()
         } )
      }

      if ( products.length ) {
         const filtered = products.flat().filter( ( product ) => { 
            return filterProducts( product )
         } )

         setFilteredProducts( filtered )
      }      
      
   }, [ vendorList, filters, mode ] )

   const newRestockedProducts = filteredProducts.filter( product => {
      if ( product.new_date && product.new_date.seconds > offset_timestamp ) return true
      if ( product.restock_date && product.restock_date.seconds > offset_timestamp ) return true
      return false
   } )

   const refs = {
      channelHeaderRef: useRef( null ),
      sealSectionRef: useRef( null ),

      // couponSectionScrollTargetRef: useRef( null ),
      couponSectionRef: useRef( null ),
      dropSectionRef: useRef( null ),
      storeSectionRef: useRef( null ),
   }
   
   const appBarRef = useRef( null )

   // Put this back in the ShabazzToolbar component
   const scrollToSection = ( ref ) => {
      if ( ref ) {
         ref.scrollIntoView( { behavior: 'smooth' } )
      }
   }

   if ( mode === "hh24" ) {
      const hempHarvestStrains = productSort( products.filter( product => {
         if (
            product.vendorSlug === 'fernvalleyfarms' ||
            product.vendorSlug === 'lostoakfarms' ||
            product.vendorSlug === 'rougeorigin' ||
            product.vendorSlug === 'horncreek' ||
            product.vendorSlug === 'pilotfarm'
         ) {
            if ( product.hh24 === true ) return true
         }
         
         // if ( product.new_date && product.new_date.seconds > offset_timestamp ) return true
         // if ( product.restock_date && product.restock_date.seconds > offset_timestamp ) return true
         return false
      } ) )

      const getParticipatingVendors = ( vendors ) => {
         const participatingVendors = vendors.filter( vendor => {
            if (
               vendor.slug === 'fernvalleyfarms' ||
               vendor.slug === 'lostoakfarms' ||
               vendor.slug === 'rougeorigin' ||
               vendor.slug === 'horncreek' ||
               vendor.slug === 'pilotfarm'
            ) {
               return true
            }
            return false
         } )
         return participatingVendors
      }

      const vendorData = {
         fernvalleyfarms: {
            strains: [
               { name: 'Blueberry Diesel', status: 'pending', new: true },
               { name: 'Bubba Kush', status: 'live', new: true },
               { name: 'Cakeberry Brulee', status: 'live', new: false },
               { name: 'CBG White', status: 'live', new: false },
               { name: 'Critical Berries', status: 'live', new: false },
               { name: 'Forbidden V', status: 'live', new: false },
               { name: 'Fruit Loopz', status: 'pending', new: false },
               { name: 'Legendary Platinum OG', status: 'live', new: false },
               { name: 'Lemon Octane', status: 'live', new: true },
               { name: 'Orange Fritter', status: 'live', new: true },
               { name: 'Rainbow Sherbert', status: 'live', new: true },
               { name: 'Pink Pineapple', status: 'live', new: false },
               { name: 'Royal OG', status: 'live', new: false },
               { name: 'Sour Hawaiian Haze', status: 'live', new: false },
               { name: 'Strawberry Diesel', status: 'live', new: true },
               { name: 'Super Sour Space Candy', status: 'live', new: false },
            ]
         },
         lostoakfarms: {
            strains: [
               { name: 'Allium OG', status: 'live', new: false },
               { name: 'Blue Nitro', status: 'live', new: false },
               { name: 'Bubba Kush 12', status: 'live', new: false },
               { name: 'Critical Berries', status: 'live', new: false },
               { name: 'Kush Loops', status: 'live', new: false },
               { name: 'LOF Kush 7', status: 'live', new: false },
               { name: 'Lavender Kush', status: 'live', new: false },
               { name: 'Lemon Octane #2', status: 'live', new: false },
               { name: 'Lemon Octane #13', status: 'live', new: false },
               { name: 'Lemon Octane #69', status: 'live', new: false },
               { name: 'OZ Kush F3 CBD', status: 'live', new: false },
               { name: 'Pre 98 Bubba Kush', status: 'live', new: false },
               { name: 'Rainbow Sherbet', status: 'live', new: false },
               { name: 'Raspberry Bear Claw', status: 'live', new: false },
               { name: 'Royal OG', status: 'live', new: false },
               { name: 'Sour Chem', status: 'live', new: false },
            ]
         },
         rogueOrigin: {
            strains: [
               { name: 'Bubba Kush 18', status: 'live', new: false },
               { name: 'Bubba Kush 59', status: 'live', new: false },
               { name: 'Bubba Kush 66', status: 'live', new: false },
               { name: 'Cake Berry Brûlée', status: 'pending', new: false },
               { name: 'Dream Weaver', status: 'live', new: true },
               { name: 'Fruity Pebbles', status: 'live', new: true },
               { name: 'Glass Slipper', status: 'pending', new: true },
               { name: 'Godfather OG', status: 'live', new: true },
               { name: 'Golden Berries', status: 'live', new: true },
               { name: 'Lifter', status: 'live', new: false },
               { name: 'Master OG', status: 'live', new: true },
               { name: 'Paradise OG', status: 'live', new: true },
               { name: 'Royal Fruit Snacks', status: 'live', new: true },
               { name: 'Royal OG', status: 'pending', new: true },
               { name: 'Sour Brûlée', status: 'pending', new: false },
               { name: 'Sour Lifter', status: 'pending', new: false },
               { name: 'Sour Space Candy', status: 'pending', new: false },
               { name: 'Sour Special Sauce', status: 'pending', new: false },
               { name: 'Sour Suver Haze', status: 'pending', new: false },
               { name: 'Super Purps', status: 'live', new: true },
            ]
         },
         pilotfarm: {
            strains: [
               { name: 'Blue Orchid', status: 'live', new: true },
               { name: 'Bubba12', status: 'pending', new: true },
               { name: 'Bubba59', status: 'pending', new: true },
               { name: 'Bubba66', status: 'live', new: true },
               { name: 'Critical Berries', status: 'live', new: true },
               { name: 'Lemon Octane', status: 'live', new: true },
               { name: 'Pear Blossom', status: 'live', new: true },
               { name: 'Pineapple Kush', status: 'live', new: true },
               { name: 'Royal OG', status: 'live', new: true },
            ]
         },
         horncreek: {
            strains: [
               { name: 'Blossom', status: 'pending', new: false },
               { name: 'Blue Magnolia', status: 'live', new: false },
               { name: 'Blue Orchid', status: 'live', new: false },
               { name: 'Bubba Kush', status: 'live', new: false },
               { name: 'Classic Cookies', status: 'pending', new: false },
               { name: 'Chemberry', status: 'live', new: false },
               { name: 'Critical Berries', status: 'pending', new: false },
               { name: 'Hawaiian Haze Sour G CBG', status: 'live', new: false },
               { name: 'Oregon Sweetgum Pear', status: 'live', new: false },
               { name: 'Kush Cake', status: 'live', new: false },
               { name: 'Lazie OG', status: 'live', new: false },
               { name: 'Legendary Jack', status: 'pending', new: false },
               { name: 'Lemon Octane', status: 'live', new: false },
               { name: 'Lifter', status: 'live', new: false },
               { name: 'OZ Kush bx', status: 'live', new: false },
               { name: 'Pineapple Kush', status: 'live', new: false },
               { name: 'Pineberry', status: 'pending', new: false },
               { name: 'Sour G', status: 'live', new: false },
               { name: 'Sour Pineapple', status: 'live', new: false },
               { name: 'Strawberry Jam', status: 'pending', new: false },
            ]
         }
      }
      return (
         <>
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#df962c' } } }
            >
               <ArrowDownwardIcon /> Fresh 2024 Harvest, Ready to Enjoy!
            </Typography>
            <Typography
                  variant='h2'
                  sx={ { fontSize: { xs: '1.3rem', sm: '30px' } } } 
               >
                  Updated Daily. Stay Tuned for More!
               </Typography>
            
            <Scroller
               handleModalClickOpen={ handleClickOpen }
               handleFriendsClickOpen={ handleFriendsClickOpen }
               userData={ userData }
               handleFav={ handleFav }
               products={ products }
               scrollToTopButton={ false }
               headerTitle="Recent CBD Flower Drops!"
               filteredProducts={ productSort( hempHarvestStrains, 'new_date' ) }
               vendorList = { vendorList || [] }
               headerBackgroundColor = 'secondary.main'
               // id="drops" // might not need.
               // ref={ refs.dropSectionRef }
               mode="basic"
               showDate={ true }
               dateCol="new_date"
            />

            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Participating Hemp Farms
            </Typography>

            <VendorLogoList
               vendorList = { getParticipatingVendors( vendorList ) }
               filteredProducts = { hempHarvestStrains }
               products = { products }
               mode="basic"
               xsWidth = { 6 }
               smWidth = { 6 }
               mdWidth = { 3 }
               clickable = { false }
               newOnly = { true }
            />

            { /* Fern Valley Farms  */ }
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Fern Valley Farms - Medford, OR
            </Typography>
            <Box sx={ styles.borderBG }>
               <Grid container spacing={ 4 } sx={ { alignContent: 'center', justifyItems: 'center'} }>
                  <Grid size={ { xs: 12, md: 3 } } sx={ { textAlign: 'center' } }>
                     <a href="https://fernvalleyfarms.com/?utm_source=hempstok&utm_id=hempharvest2024" target="_blank" rel="noreferrer">
                        <img src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/vendor-logos%2Ffernvalleyfarms.png?alt=media&token=fb67d932-503e-4dee-9780-73bc68495256" alt="Fern Valley Farms" width="100%" />
                     </a>

                     <TableContainer>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Location:</TableCell>
                                 <TableCell>Medford, OR</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Genetics:</TableCell>
                                 <TableCell>Oregon CBD</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Owner-Operator:</TableCell>
                                 <TableCell>Kelley Thomas & family</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Instagram:</TableCell>
                                 <TableCell>
                                    <a
                                       href="https://www.instagram.com/fernvalleycbd/"
                                       target='_blank'
                                       rel='noopener noreferrer nofollow'
                                    >
                                       @fernvalleycbd
                                    </a>
                                 </TableCell>
                              </TableRow>                              
                           </TableBody>
                        </Table>
                     </TableContainer>

                  </Grid>
                  <Grid size={ { xs: 12, md: 9 } }>
                     <Typography component="h3" variant='h5' sx={ { marginBottom: '1rem', textAlign: { xs: 'center', sm: 'left' }, } }>
                        About Fern Valley Farms:
                     </Typography>
                     <Typography component="p" variant='body1' gutterBottom sx={ styles.farmBioBody }>
                        Fern Valley Farms, founded in 2018 by Kelley Thomas in Southern Oregon, is a family-owned CBD farm committed to organic, high-quality hemp production. With the support of his wife Katie and sister Beverly, the farm has grown into 25 acres of greenhouses and warehouses, producing premium CBD products.
                     </Typography>
                     <Typography component="h3" variant='h5' sx={ styles.farmBioHeader }>
                        This Season's Strains:
                     </Typography>

                     <Box sx={ styles.strainStatusKey }>
                        <PendingIcon sx={ { color: '#3d5125' } } />
                        <Typography component='span'>
                           &nbsp;= Pending
                        </Typography>
                        <CheckCircleIcon sx={ { color: '#7baa45', marginLeft: '1rem' } } />
                        <Typography component='span'>
                           &nbsp;= Ready
                        </Typography>
                     </Box>

                     <Stack
                        direction="row"
                        spacing={ 1 }
                        useFlexGap
                        sx={ { flexWrap: 'wrap' } }
                        justifyContent={ { xs: "center", sm: 'left' } }
                     >
                     {
                        vendorData.fernvalleyfarms.strains.map( strain => (
                           <Chip
                              icon={ strain.status === 'pending' ? <PendingIcon /> : <CheckCircleIcon /> }
                              label={ strain.name }
                              color={ strain.status === 'pending' ? 'secondary' : 'primary' }
                              variant="contained"
                              key={ strain.name }
                              size="small"
                              sx={ { fontWeight: 'bold' } }
                           />
                        ) )
                     }
                     </Stack>
                  </Grid>
               </Grid>

               <Box component="hr" sx={ { marginTop: '3rem' } } />

               <Typography
                  variant='h2'
                  sx={ styles.sectionHeader }
               >
                  Try Fern Valley Farms' 2024 Hemp Strains:
               </Typography>
               <Scroller
                  handleModalClickOpen={ handleClickOpen }
                  handleFriendsClickOpen={ handleFriendsClickOpen }
                  userData={ userData }
                  handleFav={ handleFav }
                  products={ products }
                  scrollToTopButton={ false }
                  filteredProducts={ productSort( hempHarvestStrains.filter( strain => strain.vendorSlug === 'fernvalleyfarms' ), 'new_date' ) }
                  vendorList = { vendorList || [] }
                  // ref={ refs.dropSectionRef }
                  mode="basic"
                  vendorLogo={ false }
                  showDate={ true }
                  dateCol="new_date"
               />
            </Box>

            { /* Horn Creek Hemp  */ }
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Horn Creek Hemp - Jacksonville, OR
            </Typography>
            <Box sx={ styles.borderBG }>
               <Grid container spacing={ 4 } sx={ { alignContent: 'center', justifyItems: 'center'} }>
                  <Grid size={ { xs: 12, md: 3 } } sx={ { textAlign: 'center' } }>
                     <a href="https://horncreekhemp.com/?utm_source=hempstok&utm_id=hempharvest2024" target="_blank" rel="noreferrer">
                        <img src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/vendor-logos%2Fhorncreek.png?alt=media&token=590bcd16-2aa0-4e89-8dde-d31794490c92" alt="Horn Creek Hemp" width="100%" />
                     </a>

                     <TableContainer>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Location:</TableCell>
                                 <TableCell>Jacksonville, OR</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Genetics:</TableCell>
                                 <TableCell>East Fork, Hoku, Infinite Tree, Oregon CBD, Zera, Zoe</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Owner-Operator:</TableCell>
                                 <TableCell>Paul Murdoch & family</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Instagram:</TableCell>
                                 <TableCell>
                                    <a
                                       href="https://www.instagram.com/horn_creek/"
                                       target='_blank'
                                       rel='noopener noreferrer nofollow'
                                    >
                                       @horn_creek
                                    </a>
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>

                  </Grid>
                  <Grid size={ { xs: 12, md: 9 } }>
                     <Typography component="h3" variant='h5' sx={ { marginBottom: '1rem' } }>
                        About Horn Creek Hemp:
                     </Typography>
                     <Typography component="p" variant='body1' gutterBottom sx={ styles.farmBioBody }>
                        From humble beginnings with 300 plants, Horn Creek Farm has grown into a meticulous operation, overseeing every step of production. With a commitment to customer satisfaction, farm-direct pricing, and the healing power of nature, Horn Creek Farm continues to set the standard for quality in the CBD industry.
                     </Typography>
                     
                     <Typography component="h3" variant='h5' sx={ styles.farmBioHeader }>
                        This Season's Strains:
                     </Typography>

                     <Box sx={ styles.strainStatusKey }>
                        <PendingIcon sx={ { color: '#3d5125' } } />
                        <Typography component='span'>
                           &nbsp;= Pending
                        </Typography>
                        <CheckCircleIcon sx={ { color: '#7baa45', marginLeft: '1rem' } } />
                        <Typography component='span'>
                           &nbsp;= Ready
                        </Typography>
                     </Box>

                     <Stack
                        direction="row"
                        spacing={ 1 }
                        useFlexGap
                        sx={ { flexWrap: 'wrap' } }
                        justifyContent={ { xs: "center", sm: 'left' } }
                     >
                     {
                        vendorData.horncreek.strains.map( strain => (
                           <Chip
                              icon={ strain.status === 'pending' ? <PendingIcon /> : <CheckCircleIcon /> }
                              label={ strain.name }
                              color={ strain.status === 'pending' ? 'secondary' : 'primary' }
                              variant="contained"
                              key={ strain.name }
                              size="small"
                              sx={ { fontWeight: 'bold' } }
                           />
                        ) )
                     }
                     </Stack>

                  </Grid>
               </Grid>

               <Box component="hr" sx={ { marginTop: '3rem' } } />

               <Typography
                  variant='h2'
                  sx={ styles.sectionHeader }
               >
                  Try Horn Creek Hemp's 2024 Hemp Strains:
               </Typography>

               <Scroller
                  handleModalClickOpen={ handleClickOpen }
                  handleFriendsClickOpen={ handleFriendsClickOpen }
                  userData={ userData }
                  handleFav={ handleFav }
                  products={ products }
                  scrollToTopButton={ false }
                  filteredProducts={ productSort( hempHarvestStrains.filter( strain => strain.vendorSlug === 'horncreek' ) ) }
                  vendorList = { vendorList || [] }
                  // ref={ refs.dropSectionRef }
                  mode="basic"
                  vendorLogo={ false }
                  showDate={ true }
                  dateCol="new_date"
               />
            </Box>

            { /* Lost Oak Farms  */ }
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Lost Oak Farms - Rogue Valley, OR
            </Typography>
            <Box sx={ styles.borderBG }>
               <Grid container spacing={ 4 } sx={ { alignContent: 'center', justifyItems: 'center'} }>
                  <Grid size={ { xs: 12, md: 3 } } sx={ { textAlign: 'center' } }>
                     <a href="https://www.lostoakfarms.com/?utm_source=hempstok&utm_id=hempharvest2024" target="_blank" rel="noreferrer">
                        <img src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/vendor-logos%2Flostoakfarms.png?alt=media&token=6312862c-b274-45fd-a6e2-6cfa32d0faec&_gl=1*11ll045*_ga*MjA1NjU4ODMzLjE2ODkwNDIwMjU.*_ga_CW55HF8NVT*MTY5NjY1Njg5Ny4xMDcuMS4xNjk2NjU3ODI3LjMxLjAuMA" alt="Fern Valley Farms" width="100%" />
                     </a>

                     <TableContainer>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Location:</TableCell>
                                 <TableCell>Rogue Valley, Oregon</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Genetics:</TableCell>
                                 <TableCell>Zoë Therapeutics, Hoku Seed Co, High Alpine Genetics, Oregon CBD</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Owner-Operator:</TableCell>
                                 <TableCell>Jeshe Burch & family</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Instagram:</TableCell>
                                 <TableCell>
                                    <a
                                       href="https://www.instagram.com/lostoakfarms/"
                                       target='_blank'
                                       rel='noopener noreferrer nofollow'
                                    >
                                       @lostoakfarms
                                    </a>
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>

                  </Grid>
                  <Grid size={ { xs: 12, md: 9 } }>
                     <Typography component="h3" variant='h5' sx={ { marginBottom: '1rem' } }>
                        About Lost Oak Farms:
                     </Typography>
                     <Typography component="p" variant='body1' gutterBottom sx={ styles.farmBioBody }>
                        Lost Oak Farms, a family-run boutique hemp farm in Southern Oregon, specializes in ultra-premium, sustainably farmed CBD hemp flower, oil, and topicals. Committed to regenerative farming, they use no pesticides or chemical nutrients. Their certified products meet federal and international standards, containing less than 0.3% Delta 9 THC. Lost Oak Farms is USDA and state-licensed, and a USPS-certified hemp shipper.
                     </Typography>
                     <Typography component="h3" variant='h5' sx={ styles.farmBioHeader }>
                        This Season's Strains:
                     </Typography>

                     <Box sx={ styles.strainStatusKey }>
                        <PendingIcon sx={ { color: '#3d5125' } } />
                        <Typography component='span'>
                           &nbsp;= Pending
                        </Typography>
                        <CheckCircleIcon sx={ { color: '#7baa45', marginLeft: '1rem' } } />
                        <Typography component='span'>
                           &nbsp;= Ready
                        </Typography>
                     </Box>

                     <Stack
                        direction="row"
                        spacing={ 1 }
                        useFlexGap
                        sx={ { flexWrap: 'wrap' } }
                        justifyContent={ { xs: "center", sm: 'left' } }
                     >
                     {
                        vendorData.lostoakfarms.strains.map( strain => (
                           <Chip
                              icon={ strain.status === 'pending' ? <PendingIcon /> : <CheckCircleIcon /> }
                              label={ strain.name }
                              color={ strain.status === 'pending' ? 'secondary' : 'primary' }
                              variant="contained"
                              key={ strain.name }
                              size="small"
                              sx={ { fontWeight: 'bold' } }
                           />
                        ) )
                     }
                     </Stack>
                  </Grid>
               </Grid>

               <Box component="hr" sx={ { marginTop: '3rem' } } />

               <Typography
                  variant='h2'
                  sx={ styles.sectionHeader }
               >
                  Try Lost Oak Farms' 2024 Hemp Strains:
               </Typography>
               <Scroller
                  handleModalClickOpen={ handleClickOpen }
                  handleFriendsClickOpen={ handleFriendsClickOpen }
                  userData={ userData }
                  handleFav={ handleFav }
                  products={ products }
                  scrollToTopButton={ false }
                  filteredProducts={ productSort( hempHarvestStrains.filter( strain => strain.vendorSlug === 'lostoakfarms' ) ) }
                  vendorList = { vendorList || [] }
                  // ref={ refs.dropSectionRef }
                  mode="basic"
                  vendorLogo={ false }
                  showDate={ true }
               />
            </Box>

            { /* Pilot Farm  */ }
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Pilot Farm - Ashland, OR
            </Typography>
            <Box sx={ styles.borderBG }>
               <Grid container spacing={ 4 } sx={ { alignContent: 'center', justifyItems: 'center'} }>
                  <Grid size={ { xs: 12, md: 3 } } sx={ { textAlign: 'center' } }>
                     <a href="https://pilotfarm.com/?utm_source=hempstok&utm_id=hempharvest2024" target="_blank" rel="noreferrer">
                        <img src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/vendor-logos%2Fpilot-farm.png?alt=media&token=0a8955f5-8a01-4242-acac-a9d4f14f4eb4" alt="Pilot Farm" width="100%" />
                     </a>

                     <TableContainer>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Location:</TableCell>
                                 <TableCell>Ashland, Oregon</TableCell>
                              </TableRow>
                              { /*
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Genetics:</TableCell>
                                 <TableCell>Zoë Therapeutics, Hoku Seed Co, High Alpine Genetics, Oregon CBD</TableCell>
                              </TableRow>
                              */}
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Owner-Operator:</TableCell>
                                 <TableCell>Dolinar family</TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>

                  </Grid>
                  <Grid size={ { xs: 12, md: 9 } }>
                     <Typography component="h3" variant='h5' sx={ { marginBottom: '1rem' } }>
                        About Pilot Farm:
                     </Typography>
                     <Typography component="p" variant='body1' gutterBottom sx={ styles.farmBioBody }>
                        <p>Welcome to Pilot Farm, where we produce small-batch, hand-cultivated, USDA Certified Organic and Sun+Earth Certified hemp products.</p>
                        <p>Our journey begins with hemp plants grown on a steep canyon overlooking the Colestin Valley in Southern Oregon. At our high-elevation farm, we embrace regenerative farming and genetic diversity as core principles. We cultivate completely pesticide-free, adding nutrients sparingly only when needed. Our approach relies on natural elements—sun, native soil, and farm-made compost teas—with minimal intervention, allowing the plants to thrive in their natural environment.</p>
                        <p>Cultivation is led by our master farmer with over 30 years of experience in organic, regenerative practices across various crops, including fruits, vegetables, botanicals, cannabis, and fungi.</p>
                        <p>Our hemp products are crafted both on-site at our estate and in collaboration with trusted partners like SunGold Botanicals and East Fork Cultivars. Our product line includes flower, pre-rolls, gummies, chocolates, tinctures, body balm, and soon-to-launch beverages.</p>
                        <p>At Pilot Farm, we believe in enhancing well-being through plant-based products, grounded in sustainability and respect for all living systems. Our mission is driven by transparency and authenticity—for humanity, the planet, and beyond. We partner with brands and people who share our commitment to social good, ensuring that every product and process is aligned with ethical values. Welcome to Pilot Farm.</p>
                     </Typography>
                     <Typography component="h3" variant='h5' sx={ styles.farmBioHeader }>
                        This Season's Strains:
                     </Typography>

                     <Box sx={ styles.strainStatusKey }>
                        <PendingIcon sx={ { color: '#3d5125' } } />
                        <Typography component='span'>
                           &nbsp;= Pending
                        </Typography>
                        <CheckCircleIcon sx={ { color: '#7baa45', marginLeft: '1rem' } } />
                        <Typography component='span'>
                           &nbsp;= Ready
                        </Typography>
                     </Box>

                     <Stack
                        direction="row"
                        spacing={ 1 }
                        useFlexGap
                        sx={ { flexWrap: 'wrap' } }
                        justifyContent={ { xs: "center", sm: 'left' } }
                     >
                     {
                        vendorData.pilotfarm.strains.map( strain => (
                           <Chip
                              icon={ strain.status === 'pending' ? <PendingIcon /> : <CheckCircleIcon /> }
                              label={ strain.name }
                              color={ strain.status === 'pending' ? 'secondary' : 'primary' }
                              variant="contained"
                              key={ strain.name }
                              size="small"
                              sx={ { fontWeight: 'bold' } }
                           />
                        ) )
                     }
                     </Stack>
                  </Grid>
               </Grid>

               <Box component="hr" sx={ { marginTop: '3rem' } } />

               <Typography
                  variant='h2'
                  sx={ styles.sectionHeader }
               >
                  Try Pilot Farm's 2024 Hemp Strains:
               </Typography>

               <Scroller
                  handleModalClickOpen={ handleClickOpen }
                  handleFriendsClickOpen={ handleFriendsClickOpen }
                  userData={ userData }
                  handleFav={ handleFav }
                  products={ products }
                  scrollToTopButton={ false }
                  filteredProducts={ productSort( hempHarvestStrains.filter( strain => strain.vendorSlug === 'pilotfarm' ) ) }
                  vendorList = { vendorList || [] }
                  // ref={ refs.dropSectionRef }
                  mode="basic"
                  vendorLogo={ false }
                  showDate={ true }
               />
            </Box>

            { /* Rogue Origin  */ }
            <Typography
               variant='h2'
               sx={ { ...styles.sectionBlockHeader, ...{ backgroundColor: '#7baa45' } } }
            >
               Rogue Orign - Eagle Point, OR
            </Typography>
            <Box sx={ styles.borderBG }>
               <Grid container spacing={ 4 } sx={ { alignContent: 'center', justifyItems: 'center'} }>
                  <Grid size={ { xs: 12, md: 3 } } sx={ { textAlign: 'center' } }>
                     <a href="https://rogueorigin.com/?utm_source=hempstok&utm_id=hempharvest2024" target="_blank" rel="noreferrer">
                        <img src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/vendor-logos%2Frougeorigin.png?alt=media&token=2383d518-869b-43cd-a2c3-c77df41b1415" alt="Rogue Origin" width="100%" />
                     </a>

                     <TableContainer>
                        <Table size="small">
                           <TableBody>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Location:</TableCell>
                                 <TableCell>Eagle Point, OR</TableCell>
                              </TableRow>

                              { /*
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Genetics:</TableCell>
                                 <TableCell>Zoë Therapeutics, Hoku Seed Co, High Alpine Genetics, Oregon CBD</TableCell>
                              </TableRow>
                              */ }

                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Owner-Operator:</TableCell>
                                 <TableCell>Jacob Fisher</TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell align="right" sx={ { fontWeight: "bold" } }>Instagram:</TableCell>
                                 <TableCell>
                                    <a
                                       href="https://www.instagram.com/rogueorigincbd/"
                                       target='_blank'
                                       rel='noopener noreferrer nofollow'
                                    >
                                       @rogueorigincbd
                                    </a>
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>

                  </Grid>
                  <Grid size={ { xs: 12, md: 9 } }>
                     <Typography component="h3" variant='h5' sx={ { marginBottom: '1rem' } }>
                        About Rogue Origin:
                     </Typography>
                     <Typography component="p" variant='body1' gutterBottom sx={ styles.farmBioBody }>
                        At Rogue Origin, they're passionate about cultivating top-quality hemp flower in the Southern Oregon valley. There farm uses sustainable practices to grow rich, cannabinoid-packed buds under the warm sun. Each flower is carefully hand-trimmed and slow-cured for maximum potency and flavor. They offer a variety of CBD-rich cultivars, each third-party lab-tested and USDA-certified. Whether you're looking for relaxation or a creative boost, their hemp flower delivers.
                     </Typography>

                     <Typography component="h3" variant='h5' sx={ styles.farmBioHeader }>
                        This Season's Strains:
                     </Typography>

                     <Box sx={ styles.strainStatusKey }>
                        <PendingIcon sx={ { color: '#3d5125' } } />
                        <Typography component='span'>
                           &nbsp;= Pending
                        </Typography>
                        <CheckCircleIcon sx={ { color: '#7baa45', marginLeft: '1rem' } } />
                        <Typography component='span'>
                           &nbsp;= Ready
                        </Typography>
                     </Box>

                     <Stack
                        direction="row"
                        spacing={ 1 }
                        useFlexGap
                        sx={ { flexWrap: 'wrap' } }
                        justifyContent={ { xs: "center", sm: 'left' } }
                     >
                     {
                        vendorData.rogueOrigin.strains.map( strain => (
                           <Chip
                              icon={ strain.status === 'pending' ? <PendingIcon /> : <CheckCircleIcon /> }
                              label={ strain.name }
                              color={ strain.status === 'pending' ? 'secondary' : 'primary' }
                              variant="contained"
                              key={ strain.name }
                              size="small"
                              sx={ { fontWeight: 'bold' } }
                           />
                        ) )
                     }
                     </Stack>
                  </Grid>
               </Grid>

               <Box component="hr" sx={ { marginTop: '3rem' } } />

               <Typography
                  variant='h2'
                  sx={ styles.sectionHeader }
               >
                  Try Rogue Origin's 2024 Hemp Strains:
               </Typography>

               <Scroller
                  handleModalClickOpen={ handleClickOpen }
                  handleFriendsClickOpen={ handleFriendsClickOpen }
                  userData={ userData }
                  handleFav={ handleFav }
                  products={ products }
                  scrollToTopButton={ false }
                  filteredProducts={ productSort( hempHarvestStrains.filter( strain => strain.vendorSlug === 'rougeorigin' ), 'new_date' ) }
                  vendorList = { vendorList || [] }
                  // ref={ refs.dropSectionRef }
                  mode="basic"
                  vendorLogo={ false }
                  showDate={ true }
                  dateCol="new_date"
               />
            </Box>

            <ProductModal
               open = { productModalOpen }
               handleClose = { handleClose }
               favsArray = { userData && userData.favs }
               handleFriendsClickOpen = { handleFriendsClickOpen }
               handleModalClickOpen = { handleClickOpen }
               product = { productModalData }
            />

            <FriendsModal friendsModalOpen={ friendsModalOpen } handleClose={ handleClose } />
         </>
      )
   }

   const hempHarvest2024Header = (
      <Box sx={ styles.hempHarvestBanner } >
      <Container maxWidth="xl">
         <Grid container sx={ styles.hempMarvestBannerContent }>
            <Grid size={ { sm: 12, md: 4 } }>
               <Box
                  component="img"
                  src={ hempHarvestLogo }
                  sx={ styles.hempHarvestLogo }
                  alt="Hemp Harvest 2024 logo"
               />
            </Grid>
            <Grid size={ { sm: 12, md: 8 } }>
               <Box sx={ { padding: '1rem' } } >
                  <Typography
                     gutterBottom
                     variant='body'
                     component={ 'p' }
                     sx={ styles.hempHarvestBannerText }
                  >
                     Follow along as we track the latest hemp flower drops, deals, and discounts from our favorite hemp farms across the nation.
                  </Typography>
                  <Button
                     variant="contained"
                     color="primary"
                     size="large"
                     sx={ styles.hempHarvestBannerButton }
                     component={ Link }
                     to="/hempharvest2024"
                  >
                     View 2024 Harvest Strains
                  </Button>
               </Box>
            </Grid>
         </Grid>
      </Container>
   </Box>
   )

   return (
      <>
         <MainContainer
            fullWidth={ true }
            sx = { {
               paddingLeft: { xs: 0, sm: 'inherit' },
               paddingRight: { xs: 0, sm: 'inherit' },
               border: '5px solid red',
            } }
            ref = { appBarRef }
         >
            <Box id="main">
               <Container
                  maxWidth="xl"
                  sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
               >
                  <Typography
                     component="p"
                     variant="h5"
                     sx={ {
                        fontSize: { xs: '1rem', sm: '1.5rem' },
                     } }
                  >
                     Win 4 Zips of Rogue Origin's Best 2023 Strains
                  </Typography>
                  <Box>
                     <Button
                        variant="contained"
                        component={ RouterLink }
                        color="secondary"
                        to="/ro-giveaway"
                     >
                        Enter
                     </Button>
                  </Box>
               </Container>
               
               { /* Dis shit all needs to go into Home component */ }
               { /* Shabazz Header */}
               { headerBG && (
                  <>
                  { hempHarvest2024Header }
                  <Box
                     id="channelHeader"
                     ref={ refs.channelHeaderRef }
                     sx={ {
                        backgroundColor: headerBGColor ? headerBGColor : '#000000',
                        zIndex: 1,
                        scrollMarginTop: '65px'
                        /* 
                        '&.headerStuck': {
                           border: '5px solid pink'
                        }
                        */
                     } }
                  >
                     <Container maxWidth="xl" >
                        <img src={ headerBG } alt="Shabazz's Channel" width="100%" style={ { verticalAlign: 'bottom' } } />
                     </Container>
                  </Box>
                     <ShabazzToolbar
                        mode={ mode }
                        appBarRef={ appBarRef }
                        ref={ refs }
                        scrollToSection={ scrollToSection }
                     />
                  </>
               ) }

               { mode === "shabazz" && (
                  <>
                  { /* Intro text */}
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Grid
                           container
                           spacing={ 3 }
                           justifyContent="center"
                           alignItems="center"
                           sx={ styles.introSection }
                        >
                           <Grid size={ { xs: 12, md: 12 } }>
                              <Typography variant='h1' component={ 'h1' }
                                 sx={ {
                                 fontFamily: "'Kalam', cursive",
                                 fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                                 fontWeight: '400',
                                 textAlign: 'center',
                                 paddingTop: '2rem',
                                 color: 'secondary.main',
                                 maxWidth: '1200px',
                                 marginLeft: 'auto',
                                 marginRight: 'auto',
                                 marginBottom: '1rem'
                              } }>
                                 Welcome to Shabazz's Channel
                              </Typography>
                              <Typography
                                 variant='subtitle1'
                                 sx={{
                                    textAlign: 'center',
                                    padding: '0 1rem',
                                    fontSize: { 'sm': '1rem', 'md': '1.2rem', 'lg': '1.3rem' },
                                 }}
                              >
                                 I&apos;m passionate about cannabis, from the plant itself to the innovative work of farmers and scientists. Creating videos about CBD flower allows me to raise awareness and help consumers make smart choices. I’m dedicated to this growing industry and the positive changes it can bring. My goal is to introduce CBD to a broader market so others can experience its benefits, just like I have. Thanks for joining me on this journey.
                              </Typography>
                           </Grid>
                           { /*
                           <Grid size={ { xs: 12, md: 6 } }>
                              <Box sx={ styles.videoIframeWrapper }>
                                 <Box
                                    component="iframe"
                                    title="videos"
                                    src="https://player.vimeo.com/video/270503221?h=dbfcd48233"
                                    sx={ styles.videoIframe }
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                 />
                              </Box><script src="https://player.vimeo.com/api/player.js"></script>
                           </Grid>
                            */}
                        </Grid>
                     </Container>
                  </Box>

                  <Box
                     id="seal"
                     ref={ refs.sealSectionRef }
                     sx={ {
                        paddingBottom: '1rem',
                        backgroundColor: "grey.main",
                        scrollMarginTop: scrollMarginTop
                     } }>
                     <Scroller
                        handleModalClickOpen={ handleClickOpen }
                        handleFriendsClickOpen={ handleFriendsClickOpen }
                        userData={ userData }
                        handleFav={ handleFav }
                        products={ products }
                        scrollToTopButton={ false }
                        headerTitle="Awarded the Shabazz Seal of Approval"
                        
                        filteredProducts={ sortVendors( filteredProducts.filter( product => product.ssoa && product.ssoa === true ) ) }
                        // filteredProducts={ newRestockedProducts }
                        vendorList = { vendorList || [] }
                        headerBackgroundColor = 'secondary.main'
                     />
                  </Box>

                  <ShabazzAffiliates ref={ refs.couponSectionRef } />

                  </>
               ) }

               { /* HempHarvest2024 Banner */}
               { mode === "home" && hempHarvest2024Header }
               
               { /* Don't like this hard coded but don't want to redesign VendorInventory right now  */ }
               { mode === "home" && (
                  <>
                  { /* Intro text */}
                  { /*
                     <Box sx={{ backgroundColor: "secondary.main" }}>
                        <Container maxWidth="xl" sx={ styles.hempHarvestContainer }>
                        </Container>
                     </Box>
                  */ }
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                              fontFamily: "'Kalam', cursive",
                              fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                              fontWeight: '400',
                              textAlign: 'center',
                              paddingTop: '2rem',
                              color: 'secondary.main',
                              maxWidth: '1200px',
                              marginLeft: 'auto',
                              marginRight: 'auto'
                           } }
                        >
                           Farm-Direct CBD Flower From Top US Hemp Farms!
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /*
                  <Box
                     id="seal"
                     ref={ refs.sealSectionRef }
                     sx={ {
                        paddingBottom: '1rem',
                        backgroundColor: "grey.main",
                        scrollMarginTop: scrollMarginTop
                     } }>
                     <Scroller

               */}

               { /* Recent Drops! */}
               { ( mode === "home" || mode === 'shabazz' ) && (
                     <Box
                        pb={ 2 }
                        sx={ {
                           backgroundColor: "grey.main",
                           scrollMarginTop: scrollMarginTop,
                        } }
                        id="drops"
                        ref={ refs.dropSectionRef }
                     >
                        <Scroller
                           handleModalClickOpen={ handleClickOpen }
                           handleFriendsClickOpen={ handleFriendsClickOpen }
                           userData={ userData }
                           handleFav={ handleFav }
                           products={ products }
                           scrollToTopButton={ false }
                           headerTitle="Recent CBD Flower Drops!"
                           filteredProducts={ productSort( newRestockedProducts ) }
                           vendorList = { vendorList || [] }
                           headerBackgroundColor = 'secondary.main'
                           showDate={ true }
                        />
                     </Box>
               ) }

               <Box
                  ref = { refs.storeSectionRef }
                  sx={ { scrollMarginTop: '130px' } }
                  id="storeSection"
               >

               { /* Filter Bar */}
               <VendorProductFilter // need to i,mplement scroll mt
                  filters = { filters}
                  updateFilter = { updateFilter }
                  filterProducts = { filterProducts }
                  resetFilters = { resetFilters }
                  products = { products }
                  filteredProducts = { filteredProducts }
                  userData = { userData }
                  vendorList = { vendorList || [] }
                  mode = { mode }
               />

               { /* Still don't want to refactor it. Maybe pass this as children? */ }
               { mode === "strains" && (
                  <>
                  { /* Intro text */}
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                           fontFamily: "'Kalam', cursive",
                           fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                           fontWeight: '400',
                           textAlign: 'center',
                           paddingTop: '2rem',
                           paddingBottom: '2rem',
                           color: 'secondary.main',
                           maxWidth: '1200px',
                           marginLeft: 'auto',
                           marginRight: 'auto'
                        } }>
                           Shop the Best Farm-Direct CBD Flower from Leading Cultivars Nationwide
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /* Still don't want to refactor it. Maybe pass this as children? */ }
               { mode === "farms" && (
                  <>
                  { /* Intro text */}
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container
                        maxWidth="xl"
                     >
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                           fontFamily: "'Kalam', cursive",
                           fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                           fontWeight: '400',
                           textAlign: 'center',
                           paddingTop: '2rem',
                           paddingBottom: '2rem',
                           color: 'secondary.main',
                           maxWidth: '1200px',
                           marginLeft: 'auto',
                           marginRight: 'auto'
                        } }>
                           Shop the Top CBD Farms and Hemp Growers Across the Nation.
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /* Vendor Logos */ }
               { /* List of farms with inventory */ }
               { ( mode ==="home" || mode === "farms" || mode === "shabazz" ) && (
                  <>
                  <VendorLogoList
                     vendorList = { vendorList }
                     filteredProducts = { filteredProducts }
                     products = { products }
                  />
      
                  <FarmsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                  />
                  </>
               )}

               { /* List of strains*/ }
               { mode === "strains" && (
                  <>
                  <StrainsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                     filters = { filters }
                     updateFilter = { updateFilter }
                  />
                  </>
               ) }

               { mode === "vendor" && (
                  <FarmsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                  />
               ) }

               { /* Farm Profile */ }
               { /*
               { mode === "farmProfile" && (
                  <>
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h5' component='h1'>
                           Stoney Branch Farms
                        </Typography>
                        <Typography variant='subheader1'>
                           Stoney Branch is a family of 5th generation farmers using their knowledge & experience from the Colorodo cannabis market to cultivate craft CBD hemp flower in Illinois. All of their flower is grown, cured & packaged in-house, farm-to-flower.
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }
               */}
            </Box>

            </Box>
         </MainContainer>
         
         <ProductModal
            open = { productModalOpen }
            handleClose = { handleClose }
            favsArray = { userData && userData.favs }
            handleFriendsClickOpen = { handleFriendsClickOpen }
            handleModalClickOpen = { handleClickOpen }
            product = { productModalData }
         />
         
         <FriendsModal
            friendsModalOpen={ friendsModalOpen }
            handleClose={ handleClose }
         />

         <WelcomeModal
            modalOpen={ welcomeModalOpen }
            handleClose={ handleClose }
         />

         <Dialog
            open={ giveawayModalOpen }
            onClose={ handleClose }
            aria-labelledby="giveawayRules"
            aria-describedby="giveawayModalDescription"
         >
            <DialogTitle id="giveawayRules">
               Hempstok Giveaway - Terms and Conditions
            </DialogTitle>
            <DialogContent>
               <DialogContentText component='div' id="giveawayModalDescription">
                  <Typography>
                     <ol>
                        <li>Eligibility: Participants must be 18 years or older and residents of the United States.</li>
                        <li>Entry Requirements: To enter, participants must use Hempstok's coupon code and complete the purchase before March 1st, 2024. A Hempstok account is required to participate in giveaways. Participants are entered automatically once entry requirements are met.</li>
                        <li>Giveaway Item: The prize consists of one ounce of hemp flower. No substitutions or cash alternatives will be provided.</li>
                        <li>Entry Deadline: All purchases must be completed by 11:59 PM (Pacific Time) on January 31, 2024, to be eligible for the giveaway.</li>
                        <li>Winner Selection: The winner will be randomly selected from eligible entries and announced within 7 days of the contest closing date.</li>
                        <li>Notification: The winner will be notified via the email registered with Hemptok. If no response is received within 7 days, an alternate winner will be chosen.</li>
                        <li>Publicity: By participating, entrants agree to allow Hempstok to use their name and likeness for promotional purposes without additional compensation.</li>
                        <li>Hempstok's Right to Modify: Hempstok reserves the right to modify, suspend, or terminate the contest at any time without notice, for any reason.</li>
                        <li>Compliance with Laws: Participants must comply with all federal, state, and local laws and regulations.</li>
                        <li>Release of Liability: By participating, entrants release Hempstok from any liability for losses, damages, or injuries arising from participation in the contest.</li>
                     </ol>
                  </Typography>
                  <Typography>
                     By entering the giveaway, participants agree to these terms and conditions.
                  </Typography>
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  variant="contained"
                  color="primary"
                  onClick={ handleClose }
               >
                  Close
               </Button>
            </DialogActions>
         </Dialog>
      </>
   )
}
