import { Box, Button, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Grid from '@mui/material/Grid2'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import axios from 'axios'

import {
   getAuth,
   signInWithEmailLink,
   isSignInWithEmailLink,
   getAdditionalUserInfo,
   onAuthStateChanged
} from "firebase/auth"

import { doc, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions"

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useAuthContext } from '../../hooks/useAuthContext'
import { db } from '../../firebase/config'
import sadHemphrey from './sad-hemphrey.png'

const functions = getFunctions()
const auth = getAuth()

const styles = {
   actionBox: {
      backgroundColor: '#ffffff',
      padding: 3,
      borderRadius: '7px'
   },
   hempfrey: {
      maxWidth: '250px',
   }
}

export default function ProcessLogin() {

   const { dispatch } = useAuthContext()

   const [ mode, setMode ] = useState( '' )
   const [ error, setError ] = useState( '' )
   const [ email, setEmail ] = useState( window.localStorage.getItem('emailForSignIn') || "" )
   const [ formError, setFormError ] = useState( null )

   const handleVerifyEmailSubmit = ( e ) => {
      e.preventDefault()
   
      if ( !email ) {
         setFormError( 'Please enter a valid email' )
         return
      }

      setFormError( '' )
      setEmail( email )
   }

   const handleSubToMailtrap = ( email ) => {
      axios.get( `https://subtomailtrap-hh6b7ghz7a-uc.a.run.app`, {
         params: {
            emails: email
         }
      } )
         .then( ( res ) => {
            console.log( 'Subscribed to Mailtrap:' )
         } )
         .catch( ( error ) => {
            console.log( 'error', error )
         } )
   }

   async function requestCustomToken() {
      try {
         const user = auth.currentUser

         if (!user) {
            throw new Error("User is not authenticated");
         }

         const uid = user.uid; // Ensure the user is logged in

         const generateCustomToken = httpsCallable(functions, "generateCustomToken");

         const result = await generateCustomToken({ uid });
         const customToken = result.data.customToken;
     
         return customToken;

      } catch (error) {
         console.error("Error requesting custom token:", error.message);
      }
   }   

   const handleSignInWithEmailLink = async () => {

      const sessionId = new URLSearchParams( window.location.search ).get( 'sessionId' )

      // Update Firestore session with login state
      await updateDoc( doc( db, 'sessions', sessionId ), {
         loggedIn: true,
         userId: auth.currentUser.uid,
         idToken: ( await requestCustomToken() ),
         timestamp: Date.now(),
      } )

      if ( mode === 'newUser' ) {
         // If new user, send welcome email and subscribe to the Hempstok Reflector.
         handleSubToMailtrap( email )
      }
      
      // Clear email from storage.
      window.localStorage.removeItem( 'emailForSignIn' )

      dispatch( { type: 'LOGIN', payload: auth.currentUser } )
   }

   const handleLogIn = async () => {
      if ( isSignInWithEmailLink( auth, window.location.href ) ) {         
         if ( !email ) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again.
            setMode( 'verifyEmail' )
         } else {
            try {
               // Authenticate the user
               const result = await signInWithEmailLink( auth, email, window.location.href)
               const { isNewUser } = getAdditionalUserInfo( result )

               setMode( isNewUser ? 'newUser' : 'currentUser' )

               // Attempt session update after auth state changes
               handleSignInWithEmailLink();

               if ( isNewUser ) {
                  handleSubToMailtrap( email )
               }

               window.localStorage.removeItem( 'emailForSignIn' )
               dispatch( { type: 'LOGIN', payload: auth.currentUser } )

            } catch ( error ) {
               console.error( 'Error during login:', error.message )
               setError( `Bad Link: ${ error.code }` )
               setMode( 'error' )
            }
         }
      } else {
         setError( `No Link: ${ error.code }` )
         setMode( 'error' )
      }
   }

   useEffect( () => {
      handleLogIn()
   }, [ formError ] )

   return (
      <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
         <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
            <Box sx={ { padding: "48px 14px" } }>
            
            { mode === 'verifyEmail' && (
               <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                  <Grid size={ { sm: 6 } } sx={ { textAlign: 'center' } }>
                     <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="" width="100%" />
                  </Grid>
                  <Grid size={ { sm: 6 } } >
                     <Typography variant="h2" gutterBottom>Please Confirm Email:</Typography>
                     <Box pb="2rem">
                        <Box sx={ styles.actionBox }>
                           <form onSubmit={ handleVerifyEmailSubmit }>
                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="email">Email address</InputLabel>
                                 <FilledInput
                                    id="email"
                                    onChange={ ( e ) => setEmail( e.target.value ) }
                                    value={ email }
                                    autoComplete="email"
                                    type="email"
                                    required={ true }
                                    autoFocus={ true }
                                    disableUnderline={ true }
                                 />
                              </FormControl>
                              { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                              <Button variant="contained" size="medium" type="submit">
                                 Confirm Email
                              </Button>
                           </form>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            ) }

            { mode === 'error' && (
               <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                  <Grid size={ { sm: 6 } } sx={ { textAlign: 'center' } }>
                     <img style={ styles.hempfrey } src={ sadHemphrey } alt="Oh, Snap!" width="100%" />
                  </Grid>
                  <Grid size={ { sm: 6 } }>
                     <Typography variant="h1" gutterBottom>Oh, Snap! Hemphrey's Trippin.</Typography>
                     <Box pb="2rem">Oops: { error }</Box>

                     <Typography variant="h2" gutterBottom>What to do next?</Typography>
                     <Box sx={ styles.actionBox }>
                        <Typography variant="body1" gutterBottom>1. Close this window and try logging in again.</Typography>
                        <Typography variant="body1" gutterBottom>2. <a href="mailto:support@hempstok.com" rel="noreferrer" target="_blank">Contact support</a> if the problem persists.</Typography>
                     </Box>
                  </Grid>
               </Grid>
            ) }

            { ( mode === 'newUser' || mode === 'currentUser' ) && (
               <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                  <Grid size={ { sm: 6 } } sx={ { textAlign: 'center' } }>
                     <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="Hemphrey" width="100%" />
                  </Grid>
                  <Grid size={ { sm: 6 } }>
                     { /* Come back here. I need to figure out how to indicate a new user back to the AuthModal */ }
                     { /* That way I can show them special instructions i.e. a tour of the platform. */ }

                     { /* New User */ }
                     { mode === 'newUser' && (
                        <>
                           <Typography variant="h1" gutterBottom>Success!</Typography>
                        </>
                     ) }
                     { /* Current User Logging back in */ }
                     { mode === 'currentUser' && (
                        <>
                           <Typography variant="h1" gutterBottom>You're Logged In!</Typography>
                        </>
                     ) }
                     <Typography variant="" gutterBottom>
                        To continue, please go back to the tab or window where you were using Hempstok. If you'd like to continue here instead, <Link to="/">click here</Link>.
                     </Typography>
                  </Grid>
               </Grid>
            ) }
            </Box>
         </Container>
      </Box>
   )
}
