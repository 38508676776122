import { Box } from "@mui/material"
import { Container } from '@mui/system'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid2'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Paper } from "@mui/material"
import { useCollection } from "../../hooks/useCollection"
import { useEffect, forwardRef, useContext } from "react"
import { useTheme } from '@mui/material/styles'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import ScrollerGeneral from "../../components/Layout/Scroller/ScrollerGeneral"
import { Context } from "../../components/Layout/Page/Page"
import { usePageContext } from "../../hooks/usePageContext"

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const ShabazzAffiliates = forwardRef(( {}, ref ) => {
   const { channel } = usePageContext()
   
   const { documents: affiliates } = useCollection( 'channels/shabazz/affiliates')

   let affSorted = []

   if ( affiliates ) {
      // When I create an empty affiliate, since we're using live data, this throws an error.
      affSorted = affiliates.sort( ( a, b ) => a.name && a.name.localeCompare( b.name ) )
   }

   const theme = useTheme()

   const styles = {
      'affilatePaper': {
         height: "100%",
         width: "100%",
         position: 'relative',
         display: 'flex',
         flexDirection: 'column',
         cursor: 'pointer',
      },
      'affiliateNameWrapper': {
         display: 'flex',
         alignItems: 'center',
         height: "100%",
         justifyContent: 'center',
         width: '100%',
         paddingLeft: '.5rem',
         paddingRight: '.5rem',
      },
      'affiliateBox': {
         flex: '1 1 100%',
         minWidth: 0
      },
      'affilateName': {
         textAlign: "center",
         fontWeight: "bold",
         marginBottom: '0',
         lineHeight: '3rem',
         fontSize: { xs: '1rem' },
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      },
      'tagBox': {
         paddingLeft: '.5rem',
         paddingRight: '.5rem',
         textAlign: "center",
      },
      'couponBox': {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         padding: '.3rem 0',
         backgroundColor: '#3d5125',
      },
      'couponIcon': {
         fontSize: '1rem',
         color: '#ffffff',
         marginRight: '7px'
      },
      'couponText': {
         textAlign: 'center',
         color: '#ffffff',
         fontSize: '.7rem',
         fontWeight: 'bold'
      },
      'copyText': {
         textAlign: 'center',
         lineHeight: '1em',
         padding: '0',
      },
      'shopButtonBox': {
         textAlign: 'center',
         paddingBottom: '1rem',
      },
      'couponHeader': {
         padding: '1.5rem 16px 1rem 16px'
      }
   }

   const buildAffList = ( type ) => {
      return affSorted
         .filter( aff => aff.type === type )
         .map( ( affiliate ) => (
         <Grid size={ { xs: 12, sm: 3, md: 2, lg: 2, xl: 2 } } key={ affiliate.id }>
            <Paper
               elevation={ 1 }
               sx={ styles.affilatePaper }

               onClick={ async () => {
                  await navigator.clipboard.writeText( affiliate.couponCode )
                  handleCodeClick( affiliate.url )
               } }
            >
               <Box sx={ styles.affiliateNameWrapper }>
                  <Box sx={ styles.affiliateBox }>
                     <Typography
                        variant="body1"
                        sx={ styles.affilateName }
                     >
                        { affiliate.name }
                     </Typography>
                  </Box>
               </Box>
   
               { /*}
               { ( affiliate && affiliate.tags.length ) && (
                  <Box sx={ { display: 'flex', height: '100%', alignItems: 'center' } }>
                     <Grid sx={ styles.tagBox }>
                        <Typography variant="caption" lineHeight="1em">
                           {
                              // Print of list of tags and remove the last comma
                              affiliate.tags.join( ', ' )
                           }
                        </Typography>
                     </Grid>
                  </Box>
               ) }
               */ }
   
               <Grid sx={ styles.shopButtonBox }>
                  { affiliate && affiliate.couponCode && (
                     <>
                     <Typography
                        variant="caption"
                        textAlign="center"
                        component="div"
                     >
                        Copy Coupon:
                     </Typography>
                     <Typography
                        variant="caption"
                        textAlign="center"
                        component="div"
                        gutterBottom
                        sx={ { fontWeight: 'bold' } }
                     >
                        { `"${ affiliate.couponCode }"` }
                     </Typography>
                     </>
                  ) }
                  
                  <Button
                     size="small"
                     color="primary"
                     variant="contained"
                     startIcon={ <OpenInNewIcon /> }
                  >
                     { affiliate.couponCode ? 'Shop' : 'Visit' }
                  </Button>
               </Grid>
   
               { ( affiliate && affiliate.discountText ) && (
                  <Grid
                     sx={ styles.couponBox }
                  >
                     <LocalOfferIcon sx={ styles.couponIcon } /> 
                     <Typography sx={ styles.couponText }>
                        { affiliate.discountText }
                     </Typography>
                  </Grid>
               ) }
            </Paper>
         </Grid>
      ) )
   }

   const buildURL = ( url ) => {
      
      let linkExtension = "utm_source=hempstok&ref=hempstok"
      
      if ( channel ) {
         switch( channel ) {
            case 'shabazzstok':
               linkExtension = "utm_source=shabazzstok&ref=shabazzstok"
               break
            default:
               linkExtension = "utm_source=hempstok&ref=hempstok"
         }
      }
      
      if ( url.includes('?') ) {
         return `${ url }&${ linkExtension }` 
      }

      return `${ url }?${ linkExtension }` 
   }

   const handleCodeClick = ( url ) => {
      
      // GA4 Event
      /*
      window.dataLayer.push( {
         event: 'vendor_click_through',
         uid: user ? user.uid : '',
         email: user ? maskEmail( user.email ) : '',
         vendor_slug: product.vendorSlug,
         vendor_name: product.vendorName,
         product_id: product.id,
         product_title: product.title,
         coupon_code: couponCode
      } )
      */

      window.open( buildURL( url, '' ) ) // Not taking into account the linkExt. Reference ProductModal.js. Hmm. but it's working...
   }

   /*
   useEffect( () => {

   }, [] )
   */
   
   return (
      <>
      <Box
         id="couponSection"
         data-section="couponSection"
         ref={ ref }
         sx={ {
            paddingBottom: '1rem',
            backgroundColor: "grey.main",
            scrollMarginTop: '130px',
         } }>
         <Container
            maxWidth="xl"
            sx={ {
               paddingLeft: { xs: 0, sm: '24px' },
               paddingRight: { xs: 0, sm: '24px' },
            } }
         >
            <Box
               id='affiliates'
               sx={ {
                  position: 'sticky',
                  top: '129px', // -1px to account for a gap.
                  zIndex: 2,
                  backgroundColor: '#3d5125',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  borderBottom: '1px solid #3d5125',
                  height: '70px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderTop: '21px solid #cbccc0',
                  width: '100%',
                  } }>
                  <Box sx={{
                     flex: '1 1 100%',
                     minWidth: 0,
                  }}>
                     <Typography
                        component="h2"
                        variant="div"
                        sx={ {
                           color: '#ffffff',
                           fontWeight: 'bolder',
                           textShadow: '0px 1px 1px #6666667a',
                           fontSize: {
                              'xs': '1rem',
                              'sm': '1.2rem'
                           },
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                        } }
                        >
                        Coupons, Discounts &amp; Links
                     </Typography>
                  </Box>
                  { /*
                  <Button
                     size="small"
                     aria-label="top"
                     color='white'
                     onClick={ () => {
                        let divElement = document.getElementById('topScrollTarget')
                        divElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
                     } }
                     variant="outlined" 
                     startIcon={ <ArrowUpwardIcon /> }
                     sx={ { fontWeight: 'bold' } }
                  >
                     Top
                  </Button>
                  */ }
            </Box>
            { affSorted && (
               <Box
                  id='underCouponHeader'
                  sx={ {
                     background: '#e3e4dd',
                     paddingBottom: '14px',
                  } }
                  >
                     <Typography variant="h5" sx={ styles.couponHeader }>
                        CBD Coupons & Discounts
                     </Typography>
                     <ScrollerGeneral
                        // handleClick = { handleCodeClick }
                        items = { buildAffList( 'vendor' ) }
                     />

                     <Typography variant="h5" sx={ styles.couponHeader }>
                        Links
                     </Typography>
                     <ScrollerGeneral
                        // handleClick = { handleCodeClick }
                        items = { buildAffList( 'link' ) }
                     />

                     <Typography variant="h5" sx={ styles.couponHeader }>
                        Smoking Gear
                     </Typography>
                     <ScrollerGeneral
                        // handleClick = { handleCodeClick }
                        items = { buildAffList( 'gear' ) }
                     />
               </Box>
            ) }
         </Container>
      </Box>
      </>
   )
} )

export default ShabazzAffiliates;