import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useState, useRef } from "react"
import Grid from '@mui/material/Grid2'

import { useCollection } from '../../hooks/useCollection'
// import { useAuthContext } from '../../hooks/useAuthContext'

import MainContainer from '../../components/Layout/MainContainer/MainContainer'
import VendorProductFilter from '../../components/VendorInventory/VendorProductFilter'
// import VendorProductListBox from '../../components/VendorInventory/VendorProductListBox'
import VendorLogoGrid from '../../components/VendorInventory/VendorLogoGrid'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import shabazz from './shabazz.jpg'

export default function VendorInventory() {
   // const { user } = useAuthContext()
   const [ typeFilter, setTypeFilter ] = useState([])
   const [ stockFilter, setStockFilter ] = useState(true)
   const [ visibleVendors, setVisibleVendors ] = useState([])
   const [ productModalOpen, setProductModalOpen ] = useState( false )
   const [ productModalData, setProductModalData ] = useState( false )

   const curVisibleVendors = useRef([])
   
   const sortVendors = ( vendors ) => {
      return vendors.sort( ( a, b ) => a.title.localeCompare( b.title ) )
   }

   // Get vendorList from DB
   const { documents: vendorsRef } = useCollection( 'vendorLists/mostPopular/vendors' )

   const vendors = vendorsRef && sortVendors( vendorsRef )

   const handleClickOpen = ( product ) => {
      setProductModalData( product )
      setProductModalOpen( true );
   };
  
   const handleClose = () => {
      setProductModalOpen( false );
   };

   const styles = {
      productThumb: {
         width: '100%',
         maxWidth: '100px'
      }
   }

   const buildURL = ( url ) => {
      if ( url.includes('?') ) {
         return `${ url }&utm_source=hempstok&ref=hempstok` 
      }

      return `${ url }?utm_source=hempstok&ref=hempstok` 
   }

   return (
      <>
         <MainContainer fullWidth={true}>
            <Box pt={2} sx={{ backgroundColor: "#061c29" }}>
               <Container maxWidth="xl">
                  <img src={ shabazz } alt="shabazz" width="100%" />
               </Container>
            </Box>
            <VendorProductFilter
               typeFilter = { typeFilter }
               stockFilter= { stockFilter }
               setTypeFilter = { setTypeFilter }
               setStockFilter = { setStockFilter }
            />

            <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
               <Container maxWidth="xl">
                  <Grid container spacing={0} >
                     <Grid size={ { xs: 2 } }>
                        <VendorLogoGrid
                           vendors = { vendors }
                           visibleVendors = { visibleVendors }
                        />
                     </Grid>
                     <Grid size={ { xs: 10 } } sx={ { backgroundColor: '#e3e4dd', paddingLeft: '14px', paddingRight: '14px' } }>
                     {
                        vendors && vendors.map( ( vendor, index ) => (
                           { /*
                           <VendorProductListBox
                              slug={ vendor.slug }
                              stockFilter={ stockFilter }
                              typeFilter={ typeFilter }
                              title={ vendor.title }
                              setVisibleVendors = { setVisibleVendors }
                              curVisibleVendors = { curVisibleVendors }
                              index = { index }
                              key={ vendor.id }
                              handleModalClose = { handleClose }
                              handleModalClickOpen = { handleClickOpen }
                           />
                           */}
                        ) )
                     }
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </MainContainer>
         <Dialog
            open={ productModalOpen }
            onClose={ handleClose }
            fullWidth={ true }
            aria-labelledby="productModal"
            aria-describedby="productModalDescription"
         >
            <DialogTitle id="productModal">
               { "Don't forget to come back!" }
            </DialogTitle>
            <DialogContent>
               <DialogContentText component='div' id="productModalDescription">
                  <Grid container spacing={3} alignItems="center">
                     <Grid size={ { xs: 3 } }>
                        <img src={ productModalData.thumb_src } alt="product thumbnail" style={ styles.productThumb } />
                     </Grid>
                     <Grid size={ { xs: 9 } }>
                        <Typography variant='h6'>
                           { productModalData.title }
                        </Typography>
                     </Grid>
                  </Grid>
                  <hr />
                  <Typography variant='body1'>
                     We'll open a new window to the vendor's website. Return to this tab to continue browsing.
                  </Typography>
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  variant="contained"
                  color="primary"
                  onClick={ () => {
                     window.open( buildURL( productModalData.profile_url ) )
                     handleClose()
                     }
                  }
                  autoFocus
               >
                  Continue
               </Button>
            </DialogActions>
         </Dialog>
      </>
   )
}
