import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import { Typography } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import ProductCard from '../../ProductCard'
import VendorCard from '../../VendorCard'
import Scroller from '../../Layout/Scroller/Scroller'
import { usePageContext } from '../../../hooks/usePageContext'

const FarmsList = ({
   handleModalClickOpen,
   handleFriendsClickOpen,
   userData,
   handleFav,
   filteredProducts,
   vendorList
}) => {
   const [ vendorProducts, setVendorProducts ] = useState( {} )

   const theme = useTheme()
   const mobileView = useMediaQuery( theme.breakpoints.down( 'sm' ) )

   const { scrollMarginTop, toolbarHeight } = usePageContext()

   useEffect( () => {
      const vendorProds = {}
      filteredProducts.forEach( product => {
         const vendorSlug = product.vendorSlug
         if ( ! vendorProds[ vendorSlug ] ) {
            vendorProds[ vendorSlug ] = [];
         }
         vendorProds[ vendorSlug ].push( product );
      });
   
      setVendorProducts( vendorProds )
   }, [ filteredProducts, vendorList ] )

   // Main return
   return (
      <Box sx={ { backgroundColor: "grey.main" } }>
         {
            Object.keys( vendorProducts ).map( vendorSlug => {
               
               // Create an array of ProductCard components for each vendor
               const productCardArray = vendorProducts[ vendorSlug ].map( ( product, idx ) => {

                  return (
                     <Grid size={ { xs: 6, sm: 3, lg: 2, xl: 2 } } key={ product.id }>
                        <ProductCard
                           product={ product }
                           vendorSlug={ product.vendorSlug }
                           handleModalClickOpen={ handleModalClickOpen }
                           handleFriendsClickOpen={ handleFriendsClickOpen}
                           vendorName={ product.vendorName }
                           userData={ userData }
                           handleFav={ handleFav }
                        />
                     </Grid>
               ) } )

               const vendorData = vendorList.filter(vendor => vendor.slug === vendorSlug)[0]

               if ( vendorData ) {
                  // Add VendorCard component to the beginning of the productCardArray
                  productCardArray.unshift(
                     <Grid size={ { xs: 6, sm: 3, lg: 2, xl: 2 } } key={ vendorData.id }>
                        <VendorCard vendorData={ vendorData } />
                     </Grid>
                  )
               }

               return (
                  <>
                  { /* Mobile View */ }
                  { mobileView && (
                     <Scroller
                        handleModalClickOpen={ handleModalClickOpen }
                        handleFriendsClickOpen={ handleFriendsClickOpen }
                        userData={ userData }
                        handleFav={ handleFav }
                        filteredProducts={ filteredProducts.filter( product => product.vendorSlug === vendorSlug )}
                        vendorList = { vendorList }
                        vendorLogo = { false }
                        id={ vendorSlug }
                        headerTitle={ `${ vendorData.title }  (${ vendorProducts[ vendorSlug ].length }) ` }
                        headerBackgroundColor={ vendorData.accountType === 'certified' ? '#7baa45' : '#3d5125' }
                        vendorCardSlug = { vendorSlug }
                        scrollToTopButton = { true }
                     />
                  ) }

                  { /* Desktop View */}
                  { !mobileView && (
                     <Box>
                        <Container maxWidth="xl">
                           <Box
                              id={ vendorSlug }
                              sx={ {
                                 position: 'sticky',
                                 top: `${scrollMarginTop + toolbarHeight}px`,
                                 zIndex: 2,
                                 backgroundColor: vendorData.accountType === 'certified' ? '#7baa45' : '#3d5125',
                                 paddingLeft: '14px',
                                 paddingRight: '14px',
                                 borderBottom: '1px solid #3d5125',
                                 height: '84px',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 borderTop: '21px solid #cbccc0',
                                 width: '100%',
                                 scrollMarginTop: `${ scrollMarginTop + toolbarHeight }px`,
                              } }>
                                <Box sx={{
                                    flex: '1 1 100%',
                                    minWidth: 0,
                                 }}>
                                    <Typography
                                       component="h2"
                                       variant="div"
                                       sx={ {
                                          color: '#ffffff',
                                          fontWeight: 'bolder',
                                          textShadow: '0px 1px 1px #6666667a',
                                          fontSize: {
                                          'xs': '1rem',
                                          'sm': '1.2rem'
                                          },
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                       } }
                                    >
                                       { `${ vendorData.title }  (${ vendorProducts[ vendorSlug ].length }) ` }
                                  </Typography>
                                </Box>
                                <Button
                                    size="small"
                                    aria-label="top"
                                    color='white'
                                    onClick={ () => {
                                       // Accounts for the Shabazz header
                                       const topScrollTarget = document.querySelector( '#topScrollTarget' )
                                       topScrollTarget.scrollIntoView()
                                       //scrollIntoViewWithOffset( '#topScrollTarget', scrollMarginTop + toolbarHeight )
                                    } }
                                    variant="outlined" 
                                    startIcon={ <ArrowUpwardIcon /> }
                                    sx={ { fontWeight: 'bold' } }
                                 >Top</Button>
                           </Box>
                           <Box sx={ {
                              background: '#e3e4dd',
                              padding: '28px 14px'
                           } }>
                              <Grid container spacing={ 3 }>
                                 { productCardArray }
                              </Grid>
                           </Box>
                        </Container>
                     </Box>
                  ) }
                  </>
               )
            } )
         }
      </Box>
   )
}

export default FarmsList
