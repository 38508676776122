import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Page from '../../components/Layout/Page/Page'
import VendorInventory from '../../components/VendorInventory/VendorInventory'
import MailingListSignUp from '../../components/MailingListSignUp/MailingListSignUp'

import { useCollection } from '../../hooks/useCollection'

export default function Vendor() {
   const { slug } = useParams()
   let vendor = null

   // Get vendorList from DB
   const { documents: vendorRef } = useCollection(
      'vendors',
      [[ 'slug', '==', slug ]]
   )

   // console.log( 'vendorRef', vendorRef)
   /*
   if ( vendorRef ) {
      vendor = vendorRef[0]
   }
   */

   // Get vendor products from DB
   // I'm sure there's a way to do this and the above in one go.
   const { documents: vendorProducts } = useCollection( `vendors/${ slug }/products` )

   return (
      <Page>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Shabazz on Hempstok</title>
            <link rel="canonical" href="https://hempstok.com/shabazz" />
         </Helmet>
         <VendorInventory
            mode="vendor"
            vendors={ vendorRef || [] }
         />

         <MailingListSignUp />
      </Page>
      )
   /*
   return (
      <>
      <MainContainer fullWidth={true}>
         <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
            <Container maxWidth="xl">
               <div>Fuck me</div>
            </Container>
         </Box>
      </MainContainer>
      </>
   )

   return (
      <>
         <MainContainer fullWidth={true}>
            <Box pt={2} sx={{ backgroundColor: "#0a291d" }}>
               <Container maxWidth="xl">
                  <img src={ stoneyBranch } alt="shabazz" width="100%" />
               </Container>
            </Box>
            <VendorProductFilter
               typeFilter = { typeFilter }
               stockFilter= { stockFilter }
               setTypeFilter = { setTypeFilter }
               setStockFilter = { setStockFilter }
            />

            <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
               <Container maxWidth="xl">
                  <Grid container spacing={ 3 } >
                     <Grid xs={ 3 }>
                        <Box>
                           <Typography variant='h5' component='h1'>
                              Stoney Branch Farms
                           </Typography>
                           <Typography variant='subheader1'>
                              Stoney Branch is a family of 5th generation farmers using their knowledge & experience from the Colorodo cannabis market to cultivate craft CBD hemp flower in Illinois. All of their flower is grown, cured & packaged in-house, farm-to-flower.
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid xs={ 9 } sx={ { backgroundColor: '#e3e4dd', paddingLeft: '14px', paddingRight: '14px' } }>
                     {
                        vendor &&
                           <VendorProductListBox
                              slug={ vendor.slug }
                              stockFilter={ stockFilter }
                              typeFilter={ typeFilter }
                              title={ vendor.name }
                              key={ vendor.id }
                              handleModalClose = { handleClose }
                              handleModalClickOpen = { handleClickOpen }
                           />
                     }
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </MainContainer>
         <Dialog
            open={ productModalOpen }
            onClose={ handleClose }
            fullWidth={ true }
            aria-labelledby="productModal"
            aria-describedby="productModalDescription"
         >
            <DialogTitle id="productModal">
               { "Don't forget to come back!" }
            </DialogTitle>
            <DialogContent>
               <DialogContentText component='div' id="productModalDescription">
                  <Grid container spacing={3} alignItems="center">
                     <Grid xs={ 3 }>
                        <img src={ productModalData.thumb_src } alt="product thumbnail" style={ styles.productThumb } />
                     </Grid>
                     <Grid xs={ 9 }>
                        <Typography variant='h6'>
                           { productModalData.title }
                        </Typography>
                     </Grid>
                  </Grid>
                  <hr />
                  <Typography variant='body1'>
                     We'll open a new window to the vendor's website. Return to this tab to continue browsing.
                  </Typography>
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  variant="contained"
                  color="primary"
                  onClick={ () => {
                     window.open( buildURL( productModalData.profile_url ) )
                     handleClose()
                     }
                  }
                  autoFocus
               >
                  Continue
               </Button>
            </DialogActions>
         </Dialog>
      </>
   )
   */
}
