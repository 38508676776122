import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Grid from '@mui/material/Grid2'

// import { useAuthContext } from '../../hooks/useAuthContext'

import MainContainer from '../components/Layout/MainContainer/MainContainer'

// import { useTheme } from "@mui/material/styles"

export default function Signup() {
   /*
   const theme = useTheme()
   
   const styles = {
      vendorLogoBox: {
         padding: 2,
         borderBottom: `1px solid #7baa45`,
         cursor: 'pointer',
         [theme.breakpoints.down('sm')]: {
            
         },
      }
   }
   */

   // const { user } = useAuthContext()

   return (
      <MainContainer fullWidth={ true }>
         <Container maxWidth="xl">
         <Typography component="h1" variant="h5">Your Account</Typography>
            <Grid container spacing={ 2 } >
               <Grid size={ { sm: 9, xl: 12 } }>
               </Grid>
               <Grid size={ { sm: 6, xl: 12 } }>
                  <Box>
                     <Typography>Welcome to your account</Typography>
                  </Box>
               </Grid>
            </Grid>
         </Container>        
      </MainContainer>

   )
}
