import Container from '@mui/material/Container'
import { Box } from '@mui/system'

import MainAppBar from '../AppBar/AppBar'
import { forwardRef } from 'react'

import AuthModal from '../../AuthModal/AuthModal'

const MainContainer = forwardRef( function MainContainer( { children, fullWidth = false }, appBarRef ) {
   return (
   <>
      <MainAppBar ref={ appBarRef } />
      <Box>
        {
          !fullWidth && (
            <Container
              maxWidth="xl"
            >
              { children }
            </Container>
          )
        }
        {
          fullWidth && (
            <Box>
              { children }
            </Box>
          )
        }
      </Box>
      <AuthModal />
   </>
   )
} )

export default MainContainer
