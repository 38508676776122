const defaultCoupons = {
   akirabotanicals: {
      couponCode: 'hempstok',
      discount: '10% Off',
      couponTitle: 'Get 10% off!',
      couponBody: 'Get more green for less with this exclusive discount.'
   },
   clutchcityfarms: { // Uppromote affiliate
      couponCode: 'HEMPSTOK',
      discount: '10% off',
      couponTitle: 'Get 10% off!',
      couponBody: 'Save on premium hemp flower from Clutch City Farms'
   },
   dieselhemp: { // Some other affiliate
      couponCode: 'hempstok',
      discount: '10% off',
      couponTitle: 'Get 10% off!',
      couponBody: 'Snag a sweet discount on quality hemp from Diesel'
   },
   flowgardens: {
      couponCode: 'Hempstok10',
      discount: '10% off',
      couponTitle: 'Get 10% off!',
      couponBody: 'Save on premium hemp flower from FlowGardens',
      couponType: 'single-use'
   },
   naturalnuggs: {
      couponCode: 'hempstok',
      discount: '10% Off',
      linkExt: ''
   },
   neverwinterbotanicals: {
      couponCode: 'hempstok',
      discount: '10% Off',
      couponTitle: 'Get 10% off!',
      couponBody: 'You deserve a discount from Never Winter Botanicals',
   },
   pilotfarm: {
      couponCode: 'SHABAZZ', // Shabazz
      discount: '10% Off',
      linkExt: 'utm_source=hempstok'
      // https://pilotfarmhemp.com/discount/HEMPSTOK?redirect=/products/bubba66-organic-hemp-cbd-flower/
   },
   stoneybranch: {
      couponCode: 'hempstok',
      discount: '10% Off',
      couponTitle: 'Get 10% off!',
      couponBody: 'Get this exclusive discount from one of our best parters',
      linkExt: 'utm_source=hempstok&ref=hempstok'
   },
   fernvalleyfarms: {
      baseURL: 'https://clicks.trackcb.com/aff_c?offer_id=265&aff_id=3602&url='
   },
}

export default defaultCoupons
