import { initializeApp } from 'firebase/app'
import { getFirestore, Timestamp } from 'firebase/firestore'
import { getFunctions } from "firebase/functions"
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyC72-4ObLpQb-ygqFXf0hTlzLcSogzVQAc",
  authDomain: "hempstokapp.firebaseapp.com",
  projectId: "hempstokapp",
  storageBucket: "hempstokapp.appspot.com",
  messagingSenderId: "662746159076",
  appId: "1:662746159076:web:074e8eab582e3d6624d9f1"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore()
const auth = getAuth()
const functs = getFunctions( app )

const timestamp = Timestamp

export { db, auth, timestamp, functs }
