import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Helmet } from "react-helmet"
import Grid from '@mui/material/Grid2'
import { auth } from '../../firebase/config'
import { useFirestore } from '../../hooks/useFirestore'
import { useAuthContext } from '../../hooks/useAuthContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { doc, addDoc, serverTimestamp, collection, where, query, getDocs } from 'firebase/firestore'
import { db, timestamp } from '../../firebase/config'

import ROGiveawayImage from './RO-Giveaway.png'

import Button from '@mui/material/Button'

import { Link, NavLink as RouterLink } from "react-router-dom"

import MainContainer from '../../components/Layout/MainContainer/MainContainer'

// import { useTheme } from "@mui/material/styles"

export default function Giveaway() {
   const [ mode, setMode ] = useState( 'visitor' )

   const { addDocument, response } = useFirestore( 'giveaways' )
   const { openAuthModal } = useAuthContext()

   const user = auth.currentUser

   const handleEnterGiveaway = async () => {
      try {
         await addDocument( {
            uid: auth.currentUser.uid,
            giveawayId: 'rogue-origin-four-zip',
            registered: true,
            createdAt: serverTimestamp(),
            email: auth.currentUser.email,
         } )

         setMode( 'entered' )

      } catch ( error ) {
         console.error( "Error adding document: ", error )
      }
   }

   const isEntered = async () => {

      
      const q = query(
         collection( db, 'giveaways' ),
         where( 'uid', '==', auth.currentUser.uid ),
         where( 'giveawayId', '==', 'rogue-origin-four-zip' )
      )

      const querySnapshot = await getDocs( q )

      return querySnapshot.size > 0
   }

   useEffect( () => {
      if ( user ) {
         isEntered().then( ( entered ) => {
            if ( entered ) {
               setMode( 'alreadyEntered' )
            } else {
               setMode( 'user' )
            }
         })
      }
   }, [ user ] )


   /*
   useEffect( () => {
      // The user is already logged in
      
      isEntered()
         .then( ( entered ) => { })


      if ( user ) {
         if ( isEntered() ) {
            setMode( 'alreadyEntered' )
            return
         }

         if ( mode === 'entered' || mode === 'alreadyEntered' ) {
            return
         }

         setMode( 'user' )


      }
   }, [ user, mode ] )
   */

   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Enter the Rogue Origin Four Zip Giveaway</title>
         </Helmet>
         <MainContainer fullWidth={ true }>
            <Box sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: '48px' } }>
               <Container
                  maxWidth="md"
                  sx={ {
                     backgroundColor: '#e3e4dd',
                     paddingTop: '24px',
                     paddingBottom: '24px',
                  } }
               >
                  <Grid container spacing={ 4 } alignItems="center">
                     <Grid size={ { xs: 12, sm: 5 } }>
                        <img src={ ROGiveawayImage } alt="Rogue Origin Giveaway" width="100%" />
                     </Grid>
                     <Grid size={ { xs: 12, sm: 7 } }>
                        <Typography variant="h2" gutterBottom>Enter the RO Four Zip Giveaway!</Typography>
                        <Typography variant="body1" gutterBottom>
                           Here's your chance to snag 4 short ounces of some of Rogue Origin's best 2023 CBD flower. These strains were previously sampled and <a href="https://www.youtube.com/watch?v=xNJ7AKRZQ_k" rel="noreferrer" target="_blank">reviewd by Shabazz</a>.
                        </Typography>
                        <Box sx={ { textAlign: 'center', marginTop: '3em' } }>
                        { mode === 'visitor' && (
                           <Button
                              variant="contained"
                              size="large"
                              sx={ { fontWeight: 'bold' } }
                              onClick={ openAuthModal }
                           >
                              Login in to Enter
                           </Button>
                        ) }

                        { mode === 'user' && (
                           <Button
                              variant="contained"
                              size="large"
                              sx={ { fontWeight: 'bold' } }
                              onClick={ handleEnterGiveaway }
                           >
                              Enter Giveaway
                           </Button>
                        ) }

                        { ( mode === 'entered' || mode === 'alreadyEntered' ) && (
                           <>
                              <CheckCircleIcon color="success" size="large" />
                              <Typography variant='h5' sx={ { marginLeft: '.5em' } }>
                                 { mode === 'entered' ? 'You have entered the giveaway!' : 'You have already entered the giveaway!' }  
                              </Typography>
                           </>
                        ) }

                        </Box>
                     </Grid>
                  </Grid>

               </Container>
            </Box>
         </MainContainer>
      </>
   )
}
