import Dialog from '@mui/material/Dialog'
import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { useAuthContext } from '../../hooks/useAuthContext'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Link } from 'react-router-dom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import CancelIcon from '@mui/icons-material/Cancel'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { v4 as uuidv4 } from 'uuid'
import { db } from '../../firebase/config'
import { getFirestore, doc, setDoc, onSnapshot } from 'firebase/firestore'

import {
   getAuth,
   sendSignInLinkToEmail,
   onAuthStateChanged,
   signInWithCustomToken,
} from "firebase/auth"

const styles = {
   actionBox: {
      backgroundColor: '#ffffff',
      padding: 3,
      borderRadius: '7px',
   },
   modalContent: {
      backgroundColor: 'grey.main',
      alignItems: 'center',
   },
   leftColumn: {
      paddingTop: '1rem',
      textAlign: 'center',
      width: '100%',
      display: { xs: 'none', md: 'block' },
   },
   rightColumn: {
      backgroundColor: '#e3e4dd',
      padding: '2em',
      marginLeft: { lg: '0'},
   },
   hempfrey: {
      maxWidth: '250px',
   },
   modalHeader: {
      marginBottom: '2rem',
      textAlign: { xs: 'center', sn: 'left' },
   },
   submitButtonWrapper: {
      marginTop: '2rem',
      textAlign: 'right',
      fontWeight: 'bold !important',
      '& button': {
         fontWeight: 'bold',
      }
   },
   cancelIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      cursor: 'pointer',
   }
}

export default function AuthModal() {
   const auth = getAuth()
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


   const { user: u, dispatch, authModalOpen, closeAuthModal } = useAuthContext()

   const [ email, setEmail ] = useState( window.localStorage.getItem('emailForSignIn') || "" )
   const [ formError, setFormError ] = useState( null )
   const [ mode, setMode ] = useState( 'visitor' )
   const [ error, setError ] = useState( '' )
   const [ user, setUser ] = useState( u )

   const handleVerifyEmailSubmit = ( e ) => {
      e.preventDefault()
   
      if ( !email ) {
         setFormError( 'Please enter a valid email' )
      } else {
         setFormError( '' )
         setEmail( email )
      }

      console.log( 'formError', formError )
   }

   let unsubscribeLogin;

   function listenForLogin( sessionId ) {
      if ( !sessionId ) return () => {}; // Return a no-op cleanup function if no sessionId is found.

      const sessionDoc = doc( db, 'sessions', sessionId )

      const unsubscribe = onSnapshot( sessionDoc, async ( docSnapshot ) => {
         if ( docSnapshot.exists() ) {
            const data = docSnapshot.data()
   
            if (data.loggedIn && !auth.currentUser) {
               // User logged in from another device

               // Authenticate the user on the desktop
               try {
                  // Sign in with the custom token
                  await signInWithCustomToken(auth, data.idToken)
                  setUser( auth.currentUser)
                  dispatch( { type: 'LOGIN', payload: auth.currentUser } )
                  setMode( 'currentUser' )

                  // No need to update state here; the onAuthStateChanged listener will handle it -- not working though
               } catch (error) {
                  console.error('Error during custom token sign-in:', error);
                  setError(`Failed to log in: ${error.message}`);
               }
            }
      } } ) 

      unsubscribeLogin = unsubscribe
      return unsubscribe
   }

   useEffect(() => {
      const sessionId = localStorage.getItem('sessionId');
   
      if ( !sessionId ) {
         // No sessionId found. Skipping login listener.
         return;
      }

      // Start listening for login changes
      const unsubscribe = listenForLogin( sessionId );

      // Clean up the listener when the component unmounts or dependencies change
      return () => unsubscribe()

   }, [ authModalOpen, user, mode ] )

   useEffect(() => {
      // Listen for auth state changes
      const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
         if ( user ) {
            setUser(user);
   
            // Update context with the logged-in user
            dispatch({ type: 'LOGIN', payload: user })
   
            setMode('currentUser');
         } else {
            // User is not logged in
            setUser(null);
            setMode('visitor');
         }
      });
   
      // Clean up the auth listener on unmount
      return () => unsubscribeAuth();
   }, [ dispatch ]); // Dependency on dispatch to ensure it’s up-to-date.

   /*
   useEffect( () => {
      listenForLogin()
      
      // They are already logged in
      if ( user ) setMode( 'currentUser' )
      
      const unsubscribe = onAuthStateChanged( auth, ( user ) => {
         
         // They successfully logged in.
         if ( user ) {
            setUser( user )

            // Maybe this is ok.
            // The app wasn't updating automatically unless I did a refresh.
            dispatch( { type: 'LOGIN', payload: user } )

            setMode( 'currentUser' )
         } else {
            // They are not logged in.
            setMode( 'visitor' )
         }
      } )

      return () => unsubscribe()
   }, [ formError, user ] )
   */

   /*   
   let redirectURL = "https://hempstok.com/process-login"
   
   if ( !process.env.NODE_ENV || process.env.NODE_ENV === "development" ) {
      redirectURL = 'http://localhost:3000/process-login'
   }
   */
   
   const handleLoginLinkSubmit = async ( e ) => {
      e.preventDefault()

      const sessionId = uuidv4()
      localStorage.setItem( 'sessionId', sessionId )

      // Save the session in Firestore
      await setDoc( doc( db, 'sessions', sessionId ), { email, loggedIn: false } )

      localStorage.setItem( 'redirectAfterLogin', window.location.href );

      if (!email) {
         setFormError('Please enter a valid email.');
         return false;
      }

      const actionCodeSettings = {
         url: `${window.location.origin}/process-login?sessionId=${ sessionId }`,
         handleCodeInApp: true,
      }

      try {
         await sendSignInLinkToEmail( auth, email, actionCodeSettings )
         
         // Save the email locally so you don't need to ask the user for it again
         // if they open the link on the same device.
         window.localStorage.setItem( 'emailForSignIn', email )
         setMode( 'pendingEmailConfirmation' )
      } 
      catch ( error ) {
         setFormError( error.message )
      }
   }

   return (
      <Dialog
         open={ authModalOpen }
         onClose={ closeAuthModal }
         fullWidth={ true }
         maxWidth="md"
         fullScreen={ fullScreen }
      >
         <CancelIcon sx={ styles.cancelIcon } onClick={ closeAuthModal } />
         <Grid container spacing= { 2 } sx={ styles.modalContent } >
            <Grid size={ { md: 5 } } sx={ { width: '100%' } }>
               <Box sx={ styles.leftColumn }>
                  <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="" />
                  <Typography variant="h5">
                     We <FavoriteIcon /> CBD Farms!
                  </Typography>
               </Box>
            </Grid>
            <Grid size={ { md: 7 } }>
               <Box sx={ styles.rightColumn }>
                  { mode === 'visitor' && (
                     <>
                     <Typography variant="h5" sx={ styles.modalHeader }>Log In / Sign Up</Typography>
                     <form onSubmit={ handleLoginLinkSubmit }>
                        <Box sx={ styles.actionBox }>
                           <Typography variant="body1" mb={ 5 }>
                              Unlock special discounts, exclusive content, and be the first to explore new farm-fresh strains.
                           </Typography>
                           <FormControl variant="filled" fullWidth={ true }>
                              <InputLabel htmlFor="email">Email address</InputLabel>
                              <FilledInput
                                 id="email"
                                 onChange={ ( e ) => setEmail( e.target.value )}
                                 value={ email }
                                 autoComplete="email"
                                 type="email"
                                 required={ true }
                                 autoFocus={ true }
                                 disableUnderline={ true }
                              />
                           </FormControl>
                           { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                        </Box>
                        <Box sx={ styles.submitButtonWrapper }>
                           <Button
                              variant='contained'
                              type="submit"
                           >
                              Next
                           </Button>
                        </Box>
                     </form>
                     </>
                  ) }

                  { /* Pending Email Confirmation */ }
                  { mode === 'pendingEmailConfirmation' && (
                     <>
                        <Typography component="h1" variant="h4" textAlign="center" mb={ 3 }>Check Your Email!</Typography>
                        <Box sx={ styles.actionBox }>
                           <Typography sx={ { fontWeight: 'bold' } }  variant='body1' gutterBottom>
                              We just sent an email to { email }.
                           </Typography>
                           <Typography gutterBottom>
                              Check your email for a log in link. If you don't see it, check your spam folder.
                           </Typography>
                        </Box>
                        <Box sx={ styles.submitButtonWrapper }>
                           <Button
                              variant='contained'
                              onClick={ () => setMode( 'visitor' ) }
                           >
                              Send Again
                           </Button>
                        </Box>
                     </>
                  ) }

                  { mode === 'verifyEmail' && (
                     <Box sx={ { padding: "48px 14px" } }>
                        <Typography variant="h2" gutterBottom>Please Confirm Email:</Typography>
                        
                        <form onSubmit={ handleVerifyEmailSubmit }>
                           <Box pb="2rem">
                              <Box sx={ styles.actionBox }>
                                 <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                    <InputLabel htmlFor="email">Email address</InputLabel>
                                    <FilledInput
                                       id="email"
                                       onChange={ ( e ) => setEmail( e.target.value ) }
                                       value={ email }
                                       autoComplete="email"
                                       type="email"
                                       required={ true }
                                       autoFocus={ true }
                                       disableUnderline={ true }
                                    />
                                 </FormControl>
                                 { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                              </Box>
                           </Box>
                        </form>
                     </Box>
                  ) }
                     
                  { mode === 'newUser' && (
                     <Box>
                        <Typography variant="h1" gutterBottom>Welcome to Hempstok!</Typography>
                        <Box pb="2rem">You're all set. Let's get you started.</Box>

                        <Typography variant="h2" gutterBottom>What to do next?</Typography>
                        <Box sx={ styles.actionBox }>
                           <Typography variant="body1" gutterBottom>1. Check out the <Link to="/daily-drop">Daily Drop</Link> for today's deals.</Typography>
                           <Typography variant="body1" gutterBottom>2. <Link to="/account">Update your account</Link> settings.</Typography>
                           <Typography variant="body1" gutterBottom>3. <Link to="/about">Learn more</Link> about Hempstok.</Typography>
                        </Box>
                        <Box sx={ { textAlign: 'center', marginTop: '2rem' } }>
                           <Button variant="contained" size="medium" component={ Link } to="/">
                              Browse CBD Strains
                           </Button>
                        </Box>
                     </Box>
                  ) }

                  { mode === 'currentUser' && (
                     <Box>
                        <Typography variant="h5" gutterBottom sx={ styles.modalHeader }>Welcome, <em>{ user.email }</em>!</Typography>
                        <Typography variant="body1" gutterBottom>
                           Now that you're signed in, you've unlocked exclusive discounts, can save your favorite strains, and have full access to the <a href="https://hempstok.groups.io/g/main">Hempstok Reflector</a>. Enjoy the ride!
                        </Typography>
                        <Box sx={ { textAlign: 'center', marginTop: '2rem' } }>
                           <Button
                              variant="contained"
                              size="medium"
                              onClick={ closeAuthModal }
                           >
                              Continue
                           </Button>
                        </Box>
                     </Box>
                  ) }

                  { mode === 'error' && (
                     <Box>
                        <Typography variant="h1" gutterBottom>Oh, snap! The system is trippin.</Typography>
                        <Box pb="2rem">Here's Whassup: { error }</Box>

                        <Typography variant="h2" gutterBottom>What to do next?</Typography>
                        <Box sx={ styles.actionBox }>
                           <Typography variant="body1" gutterBottom>1. Try <Link to="/login">logging in</Link> again.</Typography>
                           <Typography variant="body1" gutterBottom>2. <a href="mailto:support@hempstok.com" rel="noreferrer" target="_blank">Contact support</a> if the problem persists.</Typography>
                        </Box>

                     </Box>
                  ) }
               </Box>
            </Grid>
         </Grid>
      </Dialog>
   )
}
