import { useState, forwardRef, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { Box } from '@mui/system'
import { NavLink as RouterLink } from "react-router-dom"
import { Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import Tooltip from '@mui/material/Tooltip'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import SettingsIcon from '@mui/icons-material/Settings'

import Avatar from '@mui/material/Avatar'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LoginIcon from '@mui/icons-material/Login'

import { useAuthContext } from '../../../hooks/useAuthContext'
import { useLogout } from '../../../hooks/useLogout'
import { usePageContext } from '../../../hooks/usePageContext'

const MainAppBar = forwardRef( ( props, appBarRef ) => {
   const { toolbarHeight } = usePageContext()
   
   const [anchorElNav, setAnchorElNav] = useState(null);
   const [anchorElUser, setAnchorElUser] = useState(null);

   const { user, openAuthModal } = useAuthContext()

   const { logout } = useLogout()

   const handleLogout = () => {
      handleCloseUserMenu()
      logout()
   }
 
   const handleOpenNavMenu = (event) => {
     setAnchorElNav(event.currentTarget);
   };

   const handleOpenUserMenu = (event) => {
     setAnchorElUser(event.currentTarget);
   };
 
   const handleCloseNavMenu = () => {
     setAnchorElNav(null);
   };
 
   const handleCloseUserMenu = () => {
     setAnchorElUser(null);
   };

   let userIcon = ''

   if ( user ) {
      userIcon = (
         <Tooltip title="Open settings">
            <Avatar
               size="large"
               onClick={ handleOpenUserMenu }
               sx={ { bgcolor: 'secondary.main' } }
            >
               <ManageAccountsIcon />
            </Avatar>
         </Tooltip>
      )
   } else {
      userIcon = (
         <Tooltip title="Login">
            <Avatar
               size="large"
               onClick={ openAuthModal }
               sx={ { bgcolor: 'primary.main' } }
            >
               <LoginIcon />
            </Avatar>
         </Tooltip>
      )      
   }
   
   return (
      <>
         <AppBar
            ref={ appBarRef }
            id="appBar"
            position="sticky"
            color="default"
            elevation={0}
            sx={ {
               borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
               height: `${ toolbarHeight }px`,
               justifyContent: 'center',
            } }
         >
            <Container
               maxWidth="xl"
            >
               <Toolbar disableGutters>
                  <RouterLink to={ "/" }>
                     <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <img
                           src="/hempstok-logo.png"
                           width="225px"
                           alt="Hempstok Logo"
                           style={{display: 'block'}}
                        />
                     </Box>
                  </RouterLink>
                  <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                     <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                     >
                        <MenuIcon />
                     </IconButton>
                     <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                           display: { xs: 'block', md: 'none' },
                        }}
                     >
                        <MenuItem onClick={ handleCloseNavMenu } component={ Link } to='/'>
                           <Typography textAlign="center">Home</Typography>
                        </MenuItem>
                        <MenuItem onClick={ handleCloseNavMenu } component={ Link } to='/about'>
                           <Typography textAlign="center">About</Typography>
                        </MenuItem>
                        <MenuItem onClick={ handleCloseNavMenu } component={ Link } to='https://www.hempaf.com/?utm_source=hempstok' target="_blank">
                           <Typography textAlign="center">Learn About CBD Flower</Typography>
                        </MenuItem>
                     </Menu>
                  </Box>
                  <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, flexGrow: 1 }}>
                     <RouterLink to={ "/" }>
                        <img
                           src="/hempstok-logo.png"
                           width="225px"
                           alt="Hempstok Logo"
                           style={{display: 'block'}}
                        />
                     </RouterLink>
                  </Box>
                  <Box sx={{
                     flexGrow: 1,
                     justifyContent: 'flex-end',
                     display: { xs: 'none', md: 'flex' }
                  }}>
                     <Button
                        sx={{ my: 2, color: 'primary', display: 'block', fontWeight: '600' }}
                        component={ Link } to="/about"
                     >
                        About
                     </Button>
                     <Button
                        sx={{ my: 2, color: 'primary', display: 'block', fontWeight: '600', marginRight: '14px' }}
                        component={ Link } to="https://www.hempaf.com/?utm_source=hempstok" target="_blank"
                     >
                        Learn About CBD Flower
                     </Button>
                  </Box>
                  <Box sx={{ flexGrow: 0 }}>
                     { userIcon }
                     <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                     >
                        { user && (
                           <MenuItem onClick={ handleLogout } component={ Link } to="">
                              <Typography textAlign="center">Log Out</Typography>
                           </MenuItem>
                        ) }
                        { !user && (
                           <MenuItem onClick={ handleLogout } component={ Link } to="/login">
                              <Typography textAlign="center">Log In</Typography>
                           </MenuItem>
                        ) }
                     </Menu>
                  </Box>
               </Toolbar>
            </Container>
         </AppBar>
      </>
   )
} )

export default MainAppBar
