import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// routes
import Home from './pages/Home/Home'
import Signup from './pages/Signup'
import ExternalLink from './pages/externalLink/externalLink'
import Vendor from './pages/Vendor/Vendor'
import Login from './pages/Login'
import Account from './pages/Account'
import Dashboard from './pages/dashboard/Dashboard'
import About from './pages/About'
import Channel from './pages/channel/Channel'
import Shabazz from './pages/shabazz/Shabazz'
// import Product from './pages/_Product'
import Drops from './pages/Drops'
import CBDFlower from './pages/CBDFlower/CBDFlower'
import HempHarvest2024 from './pages/hempharvest2024/HempHarvest2024'
import Welcome from './pages/welcome/Welcome'
import ProcessLogin from './pages/processLogin/ProcessLogin'
import ROGiveaway from './pages/ROGiveaway/ROGiveaway'

// import Gridness from './pages/gridness/Gridness'

function App() {
   const { user, authIsReady } = useAuthContext()

   let homeRoute = <Home />

   if ( localStorage.getItem('channel') ) {
      switch( localStorage.getItem('channel') ) {
         case 'shabazzstok':
            homeRoute = <Shabazz />
            break
         default:
            homeRoute = <Home />
      }
   }

   return (
      <div className="App">
         { authIsReady && (
            <Routes>
               <Route
                  path="/*"
                  element={ homeRoute }
               />
               { /*
               <Route
                  path="/signup"
                  element={ user ? <Navigate to="/" /> : <Signup /> }
               />
               <Route
                  path="/login"
                  element={ user ? <Navigate to="/" /> : <Login /> }
               />
               */ }
               <Route
                  path="/account"
                  element={ user ? <Account /> : <Navigate to="/login" /> }
               />
               <Route
                  path="/dashboard"
                  element={ user ? <Dashboard /> : <Navigate to="/login" /> }
               />
               <Route
                  path="/about"
                  element={ <About /> }
               />
               <Route
                  path="/hempharvest2024"
                  element={ <HempHarvest2024 /> }
               />
               <Route
                  path="/welcome"
                  element={ <Welcome /> }
               />
               <Route
                  path="/process-login"
                  element={ <ProcessLogin /> }
               />
               <Route
                  path="/external"
                  element={ <ExternalLink /> }
               />
               <Route
                  path="/v/:slug"
                  element={ <Vendor /> }
               />

               { /* 
               <Route
                  path="/v/:slug/:id/:title"
                  element={ <Product /> }
               />
               */}

               <Route
                  path="/drops"
                  element={ <Drops /> }
               />

               <Route
                  path="/c/:slug"
                  element={ <Channel /> }
               />
               <Route
                  path="/shabazz"
                  element={ <Shabazz /> }
               />

               <Route
                  path="cbd-flower/*"
                  element={ <CBDFlower /> }
               />

               <Route
                  path="/ro-giveaway"
                  element={ <ROGiveaway /> }
               />


            </Routes>
         ) }
      </div>
  ) }

export default App;
