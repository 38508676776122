import { useAuthContext } from '../hooks/useAuthContext'

import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'

import { getFirestore, doc, updateDoc } from 'firebase/firestore'

import { db } from '../firebase/config'

export const useLogout = () => {
   const { dispatch } = useAuthContext()

   const logout = async () => {
      // Get the sessionId from local storage
      const sessionId = localStorage.getItem('sessionId')

      // Remove sessionId from localStorage
      localStorage.removeItem('sessionId');

      // Update Firestore sessions document to loggedIn: false
      if ( sessionId ) {
         await updateDoc( doc( db, 'sessions', sessionId ), { loggedIn: false } )
         //await deleteDoc( doc( db, 'sessions', sessionId ) )
      }
      
      try {
         signOut( auth )
         dispatch( { type: 'LOGOUT' } )
      } catch ( err ) {
         console.log( err.message )
      }
  }

  return { logout }
}


/*
import { useAuthContext } from '../hooks/useAuthContext'

// firebase imports
import { auth } from "../firebase/config"
import { signOut } from "firebase/auth"

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  
  const logout = () => {
    signOut( auth )
      .then( () => {
        dispatch( { type: 'LOGOUT' } )
      } )
      .catch( ( err ) => {
        console.log( err.message )
      } )
  }

  return { logout }
}
*/
