import { createContext, useContext, useReducer, useEffect } from 'react'
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { useState } from 'react'

export const AuthContext = createContext()

export const authReducer = ( state, action ) => {
   switch ( action.type ) {
      case 'LOGIN':
         return { ...state, user: action.payload }
      case 'LOGOUT':
         return { ...state, user: null }
      case 'AUTH_IS_READY':
         return { user: action.payload, authIsReady: true }
      default:
         return state
  }
}

export const AuthContextProvider = ( { children } ) => {
   
   // Global login modal
   const [ authModalOpen, setAuthModalOpen ] = useState( false )

   const openAuthModal = () => setAuthModalOpen( true) 
   const closeAuthModal = ( e, reason) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
         return;
       }
      setAuthModalOpen( false )
   }
   
   const [ state, dispatch ] = useReducer( authReducer, { 
      user: null,
      authIsReady: false,
   } )

   useEffect( () => {
      const unsub = onAuthStateChanged( auth, user => {
         dispatch( { type: 'AUTH_IS_READY', payload: user } )
         unsub()
      } )
   }, [] )
  
  return (
    <AuthContext.Provider value={ {
      ...state,
      dispatch,
      authModalOpen,
      openAuthModal,
      closeAuthModal,
   } }>
      { children }
    </AuthContext.Provider>
  )
}

export const useAuthModal = () => useContext( AuthContext )
